*, *::before, *::after{
  font-family: 'Heebo', sans-serif;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  /* min-height: 100%;
  max-height: 100%; */
  /* background-color: #000000; */
  
  font-family: 'Heebo', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.style_TopApp__1rIBH {
  /* border: 1px solid red; */
  margin: 0;
  padding: 0;
  /* height: 100%; */
  min-height: 100vh;
  max-width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
}
  
  /* font-family: sans-serif;
  background-size: 100%;
  background-position: center;
  background-color: #0000;
  min-height: 100%;
  min-width: 100%; 
*/
  
  .style_TopApp__1rIBH div.style_Whatsapp__1vLW_{
    position: fixed;
    left: 15px;
    bottom: 80px;
    z-index: 1;
    

  }
  
  .style_TopApp__1rIBH div.style_Whatsapp__1vLW_ img{
        opacity: .9;
    }
  
  .style_TopApp__1rIBH .style_App__2u5mf {
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;
    
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp {
      display: flex;
      flex-direction: row-reverse;
      background:transparent;
      padding: 5px;
      padding-left: 15px;
      flex: 0 0 100px;
    }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_GoToStoresBtn__2pNL6{
        /* border: 1px solid red; */
        max-height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_GoToStoresBtn__2pNL6 div.style_StoresImg__hwj4F{
          padding-top: 10px;
          margin-right: 10px;
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_GoToStoresBtn__2pNL6 div.style_StoresImg__hwj4F img{
            width: 50px;
            height: 50px;
          }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_GoToStoresBtn__2pNL6 div.style_StoresImg__hwj4F img:hover{
            cursor: pointer;
          }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_AppHeader__Login__32kAk {
        /* border: 1px solid red; */
        justify-self: end;
        max-height: 130px;
        display: flex;
      }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_AppHeader__Login__32kAk .style_SocialIcons__2gKhm{
          margin-left: 15px;
          display: flex;
          justify-content: space-around;
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_AppHeader__Login__32kAk .style_SocialIcons__2gKhm > *{
          cursor: pointer;
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_AppHeader__Login__32kAk .style_SocialIcons__2gKhm > * + *{
          margin-right: 5px;
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_AppHeader__Login__32kAk .style_DivLogin__1TkDr {
          display: flex;
          flex: 1 1;
          align-self: center;
          flex-flow: row-reverse wrap;
          text-align: center;
          justify-content: center;
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_AppHeader__Login__32kAk .style_DivLogin__1TkDr .style_LoginIcon__TnFDU {
            width: 50px;
            height: 50px;
            text-align: center;
            margin: 10px 0 0 0;
          }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_AppHeader__Login__32kAk .style_LoginText__3XAsF {
          align-content: center;
          margin: 14px 0 0 10px;
          color: var(--mainWhite);
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp div.style_CartInfo__2LwvJ{
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top : 20px;
        border: 1px solid #FFFFFF;
        border-radius: 50px;
        width: 60%;
        height: 50%;
        color: #FFFFFF;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 2px;
        padding-bottom: 2px;

      }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp div.style_CartInfo__2LwvJ div.style_CartInfoTitle__2mr2y{
          /* border: 1px solid red; */
          color: rgb(255, 255, 255, 0.9);
          width: -webkit-min-content;
          width: -moz-min-content;
          width: min-content;
          font-size: 10px;
          font-weight: 0;
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp div.style_CartInfo__2LwvJ div.style_CartSum__2TNce{
          font-size: 20px;
          font-weight: 400;
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp div.style_CartInfo__2LwvJ div.style_CartWrapper__Lhnjv{
          position: relative;
          width: 40px;
          display: flex;
          justify-content: flex-end;
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp div.style_CartInfo__2LwvJ div.style_CartWrapper__Lhnjv span.style_ItemsInCart__OLBcd{
            /* border: 1px solid red; */
            position: absolute;

            left: 40%;
            top: 18%;
            width: 15px;
            height: 15px;
            
            text-align: center;
            border-radius: 50%;
          }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp div.style_CartInfo__2LwvJ div.style_CartWrapper__Lhnjv span.style_ItemsInCart__OLBcd span{
              display: flex;
              justify-content: center;
              margin-top: 1px;
              font-size: 10px;
            }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp div.style_CartInfo__2LwvJ div.style_CartWrapper__Lhnjv img{
            width: 25px;
            height: 25px;
          }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppBody__2A5Lc {
      /* flex: 1 1; */
      /* max-height: calc(100% - 200px); */
      height: 100%;
      z-index: 1;
    }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppFooter__1ruSQ {
      /* border: 3px solid red; */
      position: fixed;
      bottom: 0;
      /* left: 0; */
      /* right: 0; */
      height: 6.3%;
      width: 100%;
      overflow:hidden;
      background: transparent;
      /* display: flex;
      justify-content: center;
      align-items: center; */
/* 
      .AppFooterA {
        height: 55px;
      } */
    }

.style_LoginHere__1LlOP{
  align-self:flex-end;
}
.style_AppLogo__2pvuM {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  max-width: 80px !important;
  /* max-height: 100px !important; */
  height: 80px;
  margin: 5px;
  justify-self:center;
  margin-right: 10px;
  margin-left: 10px;
  overflow: hidden;
}
/* pointer-events: none; */
.style_AppLogo__2pvuM img {
    height: 80px;
    position: relative;
    max-height: none;
    max-height: initial;
    max-width: none;
    max-width: initial;
  }
.style_AppLogo__2pvuM:hover{
  //cursor: pointer !important;
}

.style_AppTopMenu__2j_O6 {
  text-align: center;
  display: flex;
  flex-flow: row-reverse wrap;
  justify-content: flex-end;
  align-content: center;
  padding: 5px;
  flex: 1 1;
}
.style_AppTopMenu__2j_O6.style_button__Bj4Tv {
  background-color: transparent;
  color: var(--mainWhite);
}



.style_bg_second__1h95O {
  background-color: var(--thinScrBg);
}

.style_BigappsLogo__3jCq9{
  max-width: 100px;
  height: 30px;
}

/* @media (max-width: 850px){
  div.TopApp{
    div.App{
        div.AppHeader{
          div.AppHeader__Login {
            div.DivLogin{
              div.LogIconDiv{
              }
            }
          }
        }
    }
  }
} */


/* @media screen and (max-width: 600px) {
    .AppTopMenu {
      display: flex;
      flex-flow: column-reverse wrap;
    }
    .AppHeader {
      color:black;
      display: flex;
      flex-direction: column;
      align-content: center;
    }
    .AppLogo {
      display: none;
    }
    .AppBody{
      margin-bottom: 50px;
      display: flex;
      flex-flow: column-reverse;
      justify-content: space-around;
    }
  } */


  @media (max-height: 300px) {
      div.style_TopApp__1rIBH div.style_Whatsapp__1vLW_{
        width: 0;
      }
  }
/* .Container{
  align-self: center;
}
div.AppTopMenu {
    text-align: center;
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: space-around;

    margin: 5px;
}

div.menubtn {
  padding: 10px;
  background-color: transparent;
  border: 0px;
  color: var(--mainWhite);
  font-size: 24px;
  font-weight: 400;
  cursor: pointer;

  &.menubtnActive {
    text-decoration: underline var(--mainWhite);
  }
}
input.searchbox {
    background: no-repeat right;
    background-size: 20px;
    width: 200px;
    cursor: pointer;
    border: 1px solid var(--mainWhite);
    border-radius: 31px;
    color: black;
} */

/* @media screen and (max-width: 600px) {
    .AppTopMenu {
      display: flex;
      flex-flow: column-reverse wrap;
    }
  } */
.style_SocialIcons__25pCt{
    margin-top: 8px;
}

div.style_Container__6W-ru{
    align-self: center;
}

div.style_Container__6W-ru div.style_AppTopMenu__1DmL2 {
        text-align: center;
        display: flex;
        flex-flow: row-reverse wrap;
        justify-content: space-around;
        margin: 5px;

        display: flex;
        justify-content: center;
        align-items: center;

        /* div.SearchContainer{
            border: 2px solid blue;
            margin-right: 2vw;
        } */
        
    }

div.style_Container__6W-ru div.style_menubtn__36WYm {
        padding: 10px;
        background-color: transparent;
        border: 0px;
        color: var(--mainWhite);
        font-size: 24px;
        font-weight: 200;
        cursor: pointer;
    }

div.style_Container__6W-ru div.style_menubtn__36WYm.style_menubtnActive__23guY {
            font-weight: 400;
            -webkit-text-decoration: underline var(--mainWhite);
                    text-decoration: underline var(--mainWhite);
        }

div.style_Container__6W-ru input.style_searchbox__17oQG {
        background: no-repeat right;
        background-size: 20px;
        width: 200px;
        cursor: pointer;
        border: 1px solid var(--mainWhite);
        border-radius: 31px;
        color: black;
    }

div.style_Container__6W-ru div.style_BigappsLogo__2RIyD{
        /* border: 1px solid red; */
        width: 50%;
        left: 0;
        right: 0;
        margin: auto;
        margin-top: auto;
        cursor: pointer;
        margin-top: 10%;
        opacity: .7;
    }
div.style_Container__3jc5z{
    margin-right: 10px;
    color: #FFF;
}
    div.style_Container__3jc5z div.style_InputField__3gDTz{
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
        border: 2px solid #FFF;
        border-radius: 20px;
        padding: 6px;
        
        height: 30px; /*ADDED!*/
        overflow: hidden; /*ADDED!*/

        transition: width .6s;
    }
    div.style_Container__3jc5z ::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #FFFFFFAF;
        opacity: 1; /* Firefox */
      }
    div.style_Container__3jc5z :-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #FFFFFFAF;
        opacity: 1; /* Firefox */
      }
    div.style_Container__3jc5z ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #FFFFFFAF;
        opacity: 1; /* Firefox */
      }
    div.style_Container__3jc5z :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #FFFFFFAF;
      }
    div.style_Container__3jc5z ::-ms-input-placeholder { /* Microsoft Edge */
        color: #FFFFFFAF;
      }
div.style_container__2CilB {
  align-self: center;
  /* height: 100%; */
}
  /* height: 100vh; */
  div.style_container__2CilB div.style_AppBody__7B4B3 {
    /* height: 100%; */
  }

/* @media screen and (max-width: 600px) {
    div.AppBody {
      width: 100vw;
      height: 100%;
      display: flex;
      flex-flow: column-reverse wrap;
    }
  } */

/* div.container {
  align-self: flex-start;
  flex: 1;
  justify-self: stretch;
  height: 100%;
} */
/* div.ShopMenu {
  display: flex;
  flex-flow: row-reverse wrap;
  min-width: 90%;
  text-align: center;
  justify-content: space-between;
  height: 100%;
} */

/* @media screen and (max-width: 600px) {
    div.ShopMenu {
      display: flex;
      flex-flow: column-reverse wrap;
    }
  } */
@media screen and (min-width: 701px) {
div.style_Container__2mrMX{
  /* align-self: center; */
  justify-self: right;
  flex-flow: column nowrap;
  height: 77vh;
  max-height: 77vh;
  /* max-height: 600px; */
  margin-top: 5px;
  /* flex: 1; */
  overflow-x: auto;
  /* white-space: nowrap; */
  border-radius: var(--brdRadius) 0 0 var(--brdRadius);
  background-color: var(--mainColor);
}

  div.style_Container__2mrMX div.style_TopDiv__2wfYY {
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    max-width: 100px;
    padding: 20px;
    margin: 5px;
  }


/* .TopDiv button {
  background-color: transparent;
  color: var(--mainWhite);
  border: 0px;
} */
}

@media screen and (max-width: 700px) {
  div.style_Container__2mrMX{
    position: fixed;
    bottom: 0;
    width: 100vw;
    /* border-radius: 8px 8px 0 0; */
    background-color: var(--mainColor);
    display: flex;
    justify-content: center;
    overflow: auto;
  }

    div.style_Container__2mrMX div.style_TopDiv__2wfYY {
      margin-top: 5px;
      display: flex;
      /* gap: 15px; */
      /* margin-left: 8px; */
      /* margin-right: 8px; */
      overflow: auto;
      scrollbar-height: 3px;
    }

    div.style_Container__2mrMX div.style_TopDiv__2wfYY > *{
      padding-left: 12px;
    }

    div.style_Container__2mrMX div.style_TopDiv__2wfYY:nth-child(1){
      padding-right: 12px;
    }

    div.style_Container__2mrMX div.style_TopDiv__2wfYY::-webkit-scrollbar{
      height: 3px;
    }


}


@media (max-height: 300px) {
  div.style_Container__2mrMX{
    height: 0;
  }
}
@media (min-width: 701px) {
  div.style_Container__3ttpU{
    align-self: center;
    justify-self:right;
  }
  
    div.style_Container__3ttpU div.style_TopDiv__2UgMX {
      text-align: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-around;
      color: var(--mainWhite);
      margin-bottom: 15px;
      cursor: pointer;
    }
        div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_Cat__1f3jW div.style_ItemIcon__1feJp {
          width: 66px;
          height: 66px;
          border-radius: 50%;
          border: 2px solid var(--mainWhite);
        }
      
          div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_Cat__1f3jW div.style_ItemIcon__1feJp.style_ItemIconSelected__2A00s {
            background-color: var(--mainWhite);
          }
    
          div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_Cat__1f3jW div.style_ItemIcon__1feJp img.style_ItemImg__3A7lq {
            max-width: 56px;
            max-height: 56px;
            margin-top: 5px;
          }
      
        div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_Cat__1f3jW div.style_ItemName__57Cy6 {
          width: 110px;
          margin-left: -21px;
          margin-right: -21px;
          font-weight: 300;
          font-size: 21px;
        }

    div.style_Container__3ttpU div.style_SubcatName__1q5iM{
      color: #FFF;
      margin-bottom: 15px;
    }
      div.style_Container__3ttpU:last-of-type div.style_TopDiv__2UgMX {
        margin-bottom: 0px;
      }
}

@media (max-width: 700px) {
  div.style_Container__3ttpU{
    /* border: 1px solid blue; */

    /* align-self: center;
    justify-self:right; */
    height: 100%;
    max-height: 100%;
  }
    div.style_Container__3ttpU div.style_TopDiv__2UgMX {
      
      height: 100%;
      max-height: 100%;

      display: flex;
    }
      /* flex-direction: column; */
      
      div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_ItemIcon__1feJp {
        width: 50px;
        max-width: 50px;
        max-height: 50px;
        border: 2px solid var(--mainWhite);
        border-radius: 50%;
        color: var(--mainWhite);
      }
        
        div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_ItemIcon__1feJp.style_ItemIconSelected__2A00s {
          background-color: var(--mainWhite);
        }

        div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_ItemIcon__1feJp img.style_ItemImg__3A7lq {
          width: 50px;
          height: 50px;
          margin: 0px;
          padding: 0px;
        }

      div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_ItemName__57Cy6 {
        margin-top: 2px;
        width: 50px;
        height: 20px;
        font-weight: 400;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--mainWhite);
      }

      div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_Subcats__385W8{
        /* border: 1px solid red; */
        display: flex;
        /* justify-content: center; */
        align-items: center;
      }

      div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_SubcatName__1q5iM{
        width: -webkit-min-content;
        width: -moz-min-content;
        width: min-content;
        color: #FFF;
        font-size: 13px;
      }

      div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_Subcats__385W8 > *{
        margin-right: 6px;
        margin-left: 6px;
      }
}

div.style_LoaderContainer__1Qgkg{
  justify-self: right;
  flex-flow: column;
  flex: 1 1;
  margin: 0;
  border-radius: var(--brdRadius);
  background-color: var(--mainWhite);
  padding: 15px;
  min-height: 140px;
}

/* @media screen and (min-width: 601px) { */
  div.style_Container__3XA4I{
    justify-self: right;
    flex-flow: column nowrap;
    /* max-width: 60vw; */
    max-height: 77vh;
    margin-bottom: 50px;
    flex: 4 4;
    overflow-x: auto;
    /* white-space: nowrap; */
    margin: 5px 5px 0 0;
    border-radius: var(--brdRadius);
  }
  div.style_Container__3XA4I div.style_TopDiv__24gMi {
      text-align: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
    }
  div.style_Container__3XA4I div.style_TopDiv__24gMi div.style_Oops__Hm9hW{
        /* border: 1px solid red; */
        margin: auto;
        margin-top: 20vh;
        color: #FFF;
      }
  div.style_Container__3XA4I div.style_TopDiv__24gMi div.style_Oops__Hm9hW div.style_title__2NuaZ{
          text-align: center;
          font-size: 3.5vw;
        }
  div.style_Container__3XA4I div.style_TopDiv__24gMi div.style_Oops__Hm9hW div.style_subtitle__2wmmU{
          text-align: center;
          font-size: 1.7vw;
        }

@media (max-width: 600px) {
  div.style_Container__3XA4I{
    /* border: 1px solid red; */
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
  }
    
    div.style_Container__3XA4I div.style_TopDiv__24gMi{
      /* border: 1px solid blue; */
      margin: auto;
      margin-top: 15px;
      margin-bottom: 65px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      /* justify-content: space-between; */
      justify-content: flex-start;

      width: 84%;
    }
        div.style_Container__3XA4I div.style_TopDiv__24gMi div.style_Oops__Hm9hW div.style_title__2NuaZ{
          font-size: 7vw;
        }
        div.style_Container__3XA4I div.style_TopDiv__24gMi div.style_Oops__Hm9hW div.style_subtitle__2wmmU{
          font-size: 4.7vw;
        }

  div.style_LoaderContainer__1Qgkg{
    margin: 20px;
  }
}
@media (min-width: 601px){
  div.style_Container__28A55{
    justify-self: right;
    flex-flow: column nowrap;
    flex: 1 1;
    margin: 0 0 5px 0;
    border-radius: var(--brdRadius);
    background-color: var(--mainWhite);
    position: relative;
  }
  
    div.style_Container__28A55:last-of-type {
      margin-bottom: 0px;
    }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE {
      /* text-align: center; */
      display: flex;
      flex: 1 1;
      min-height: 140px;
      flex-flow: row wrap;
      justify-content: space-between;
      color: black;
      padding: 5px;
    }
  
      div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 {
        flex: 0 0 180px;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        align-content: center;
        padding: 5px;
      }
  
        div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD {
          /* border: 1px solid red; */
          color: var(--mainColor);
          display: flex;
          justify-content: flex-end;
        }
  
          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy {
            position: relative;
            height: 30px;
            /* width: 100px; */
            text-align: left;
            cursor: help;
          }
  
            div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_label__33-E9:hover{
              opacity: .6;
            }
            
            div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_DescOuterPopup__MRmuL {
              position: fixed;
              max-width: 500px;
              z-index: 1;
            }
  
              div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_DescOuterPopup__MRmuL .style_DescArrow__1HPRO {
                height: 15px;
                width: 15px;
                transform: rotate(45deg);
                border: 1px solid var(--black);
                position: absolute;
                left: -7px;
                top: 24px;
                z-index: 1;
              }
  
              div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_DescOuterPopup__MRmuL .style_DescArrow2__9MqhF {
                height: 13px;
                width: 13px;
                transform: rotate(45deg);
                position: absolute;
                left: -6px;
                top: 25px;
                z-index: 3;
                background-color: var(--mainWhite);
              }
  
              div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_DescOuterPopup__MRmuL .style_DescOuterPopupIn__2-4mM {
                border: 1px solid var(--black);
                display: inline-block;
                padding: 10px;
                padding-left: 15px;
                padding-right: 15px;
                background-color: var(--mainWhite);
                text-align: right;
                position: -webkit-sticky;
                position: sticky;
                z-index: 2;
                color: var(--black);
                border-radius: var(--brdRadius);
              }
  
                div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_DescOuterPopup__MRmuL .style_DescOuterPopupIn__2-4mM .style_DescPopupIcon__1Bkme {
                  width: 100%;
                  height: 30px;
                }
  
                div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_DescOuterPopup__MRmuL .style_DescOuterPopupIn__2-4mM .style_DescPopupText__2SazR {
                  white-space: pre-wrap;
                }
        
        div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD > * + * {
          margin-right: 20px;
        }

        div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftBottom__1SvMg {
          display: flex;
          flex-flow: row nowrap;
          width: 100%;
          align-content: flex-end;
          justify-content: center;
          border-radius: 50px;
          border-width: 0px;
          height: 45px;
          position: relative;
        }
  
          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftBottom__1SvMg div.style_BtnLeftButton__1sEoN {
            align-self: center;
            justify-self: center;
            white-space: nowrap;
            padding: 5px 0 5px 0;
            font-size: 24px;
            color: var(--mainWhite);
            border-radius: 50px;
            border-width: 0px;
            margin-left: 15px;
          }
          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftBottom__1SvMg div.style_BtnLeftButton__1sEoN:hover{
            cursor: pointer;
          }
          
          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftBottom__1SvMg div.style_PlusLeftButton__2vwgt {
            margin-top: 10px;
            margin-left: 5px;
            width: 25px;
            height: 25px;
            color: white;
            line-height: 25px;
            font-size: 43px;
            font-weight: 300;
            color: var(--mainWhite);
          }
          
          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftBottom__1SvMg div.style_PlusLeftButton__2vwgt:hover{
            cursor: pointer;
          }
  
          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftBottom__1SvMg div.style_PlusButton__27SSb{
            position: absolute;
            margin-top: 10px;
            margin-right: 20px;
            right: 10px;
            color: white;
            line-height: 25px;
            font-size: 33px;
            font-weight: 300;
            color: var(--mainWhite);
          }
  
          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftBottom__1SvMg div.style_ItemAmount__2c1RW{
            /* position: absolute; */
            margin-top: 10px;
            align: center;
            color: white;
            line-height: 25px;
            font-size: 20px;
            font-weight: 400;
            color: var(--mainWhite);
          }
  
          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftBottom__1SvMg div.style_MinusButton__33NZU{
            position: absolute;
            margin-top: 10px;
            margin-left: 20px;
            left: 10px;
            color: white;
            line-height: 25px;
            font-size: 53px;
            font-weight: 300;
            color: var(--mainWhite);
          }
  
          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftBottom__1SvMg div.style_MinusButton__33NZU:hover{
            cursor: pointer;
          }
          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftBottom__1SvMg div.style_PlusButton__27SSb:hover{
            cursor: pointer;
          }
  
      div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp {
        display: flex;
        justify-content: flex-start;
        flex-flow: column nowrap;
        flex: 3 3;
      }
  
        div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_ItemName__3OpHN {
          display: flex;
          word-wrap: break-word;
          padding-top: 5px;
          font-size: 22px;
        }

          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_ItemName__3OpHN div {
            word-break: break-word;
          }
        
        div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_ItemPrice__32wT- {
          flex: 2 2;
          text-align: start;
          /* justify-content: center; */
          padding-top: 26px;
          font-size: 26px;
          font-weight: 600;
        }
          /* display: flex;
          gap: 10px; */
  
          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_ItemUnits__1yR53 div.style_Unit__3UDVr{
            width: 80px;
            height: 24px;
            font-size: 20px;
            border-radius: var(--brdRadius);
            border:1px solid var(--mainColor);
            margin-bottom: 5px;
            font-size: 17px;
            line-height: 23px;
          }
          /* align-self: center; */
          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_ItemUnits__1yR53 div.style_Unit__3UDVr:hover{
            cursor: default;
          }
  
      div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU {
        cursor: pointer;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        align-content: center;
        flex: 0 0 240px;
        margin: 5px;
      }
  
        div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU img.style_ItemImg__3Ws8H {
          max-width: 135px;
          height: 90px;
          margin: 0px;
        }
  
        div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU div.style_ItemFlags__2FsAV {
          display: flex;
          text-align: center;
          justify-content: center;
        }
  
        div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU div.style_iFlags__2jAOm {
          color: var(--mainWhite);
          width: 80%;
          max-width: 200px;
          border-radius: var(--brdRadius);
          height: 24px;
          font-size: 15px;
          font-weight: 500;
        }
  
          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU div.style_iFlags__2jAOm.style_iNew__14Bhu {
            border:1px solid  #FF8000;
            background-color: #FF8000;
          }
  
          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU div.style_iFlags__2jAOm.style_iSale__Iy453 {
            border:1px solid  #E10000;
            background-color: #E10000;
          }
  
          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU div.style_iFlags__2jAOm.style_iSeason__tDUrc {
            border:1px solid  #FF8000;
            background-color: #FF8000;
          }
  
    div.style_Container__28A55 div.style_DivNotInStock__3QKGD {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      color: var(--mainWhite);
      border-radius: var(--brdRadius);
      background-color: rgba(70, 70, 70, 0.8); 
      line-height: 140px;
      font-size: 60px;
      font-weight: 600;
    }
}

/* @media screen and (max-width: 600px) {
    div.TopDiv {
      display: flex;
      flex-flow: row wrap;
    }
    div.ItemIcon {
      width: 50px;
      max-width: 50px;
      max-height: 50px;
      border:1px solid  var(--mainWhite);
      border-radius: 50%;
    }
    img.ItemImg {
      width: 50px;
      height: 50px;
      margin: 0px;
    }
  } */


  @media (max-width: 600px) {
    div.style_Container__28A55{
      background-color: #FFFFFF;
      border-radius: 10px;
      overflow: hidden;
      width: 40vw;
      height: 200px;
      margin-left: auto;
      margin-bottom: 10px;
      position: relative;
    }
      /* overflow: visible; */

        div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0{
          display:flex;
          justify-content: center;

        }

          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD {
            /* border: 1px solid red; */
            position: absolute;
            top: 50%;
            left: 10px;
            color: var(--mainColor);
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            font-size: 8px;
          }
    
            div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy {
              /* border: 1px solid red; */
              position: relative;
              /* height: 30px; */
              /* width: 100px; */
              text-align: left;
              cursor: help;
              width: 60%;
              margin-right: auto;
            }
              

              div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy div.style_label__33-E9{
                /* border: 1px solid blue; */
                font-size: 11px;
              }

              div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_DescOuterPopup__MRmuL {
                position: fixed;
                left: -40px;
                z-index: 1;
              }
    
                div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_DescOuterPopup__MRmuL .style_DescArrow__1HPRO {
                  height: 15px;
                  width: 15px;
                  transform: rotate(45deg);
                  border: 1px solid var(--black);
                  position: absolute;
                  left: -7px;
                  top: 24px;
                  z-index: 1;
                }
    
                div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_DescOuterPopup__MRmuL .style_DescArrow2__9MqhF {
                  height: 13px;
                  width: 13px;
                  transform: rotate(45deg);
                  position: absolute;
                  left: -6px;
                  top: 25px;
                  z-index: 3;
                  background-color: var(--mainWhite);
                }
    
                div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_DescOuterPopup__MRmuL .style_DescOuterPopupIn__2-4mM {
                  background-color: white;
                  min-width: 80px;



                  border: 1px solid var(--black);
                  display: inline-block;
                  padding: 10px;
                  padding-left: 15px;
                  padding-right: 15px;
                  background-color: var(--mainWhite);
                  text-align: center;
                  position: -webkit-sticky;
                  position: sticky;
                  z-index: 2;
                  color: var(--black);
                  border-radius: var(--brdRadius);
                }
    
                  div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_DescOuterPopup__MRmuL .style_DescOuterPopupIn__2-4mM .style_DescPopupIcon__1Bkme {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    /* height: 30px; */
                    height: 100%;
                  }
    
                  div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_DescOuterPopup__MRmuL .style_DescOuterPopupIn__2-4mM .style_DescPopupText__2SazR {
                    white-space: pre-wrap;
                  }
          
          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftUp__2gtHD > * + *{
            margin-top: 3px;
          }

          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftBottom__1SvMg {
            color: #FFFFFF;
            margin-top: 13px;
            text-align: center;
            display: flex;
            flex-flow: row nowrap;
            width: 89%;
            align-content: flex-end;
            justify-content: center;
            /* gap: 5px; */
            border-radius: 50px;
            border-width: 0px;
            height: -webkit-max-content;
            height: -moz-max-content;
            height: max-content;
            position: relative;
          }
          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 div.style_DivLeftBottom__1SvMg > * + *{
            margin-right: 5px;
          }

        div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp{
          display: flex;
          flex-direction: column;
        }

          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_ItemName__3OpHN{
            width: 100%;
            font-size: 13px;
            justify-content: center;
            max-height: 20px;
            padding: 0 5px 0 5px;
          }
            
            div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_ItemName__3OpHN div{
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_ItemPrice__32wT-{
            font-size: 13px;
            font-weight: 600;
            height: 20px;
          }

          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_ItemUnits__1yR53 {
            /* align-self: center; */
            /* display: flex;
            gap: 10px; */
            margin-right: 10px;
            margin-top: 10px;
          }

            div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_ItemUnits__1yR53 div.style_Unit__3UDVr{
              border: 1px solid red;
              /* width: 60px;
              height: 18px; */
              width: 45px;
              height: 16px;
              font-size: 13px;
              border-radius: var(--brdRadius);
              border:1px solid var(--mainColor);
              margin-bottom: 5px;
              line-height: 17px;
            }

        div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU{
          /* border: 1px solid red; */
          display: flex;
          justify-content: center;

          position: relative;
          padding-top: 15px;
        }

          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU img.style_ItemImg__3Ws8H {
            /* width: 90px; */
            height: 60px;
            /* margin: 0px; */
          }

          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU div.style_ItemFlags__2FsAV {
            width: 16px;
            height: 100%;
            
            position: absolute;
            top: 0;
            left: 6%;
          }

            div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU div.style_ItemFlags__2FsAV div.style_iFlags__2jAOm {
              color: var(--mainWhite);
              border-radius: 0 0 var(--brdRadius) var(--brdRadius);
              height: 80%;
              text-align: center;
              font-size: 13px;
              font-weight: 500;
              overflow: hidden;
            }
              
              div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU div.style_ItemFlags__2FsAV div.style_iFlags__2jAOm.style_iNew__14Bhu {
                padding-top: 80%;

                border:1px solid  #FF8000;
                background-color: #FF8000;
              }
                
                div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU div.style_ItemFlags__2FsAV div.style_iFlags__2jAOm.style_iNew__14Bhu div{

                  -webkit-transform: rotate(-90deg);
    
                  /* Firefox */
                  -moz-transform: rotate(-90deg);
                
                  /* IE */
                  -ms-transform: rotate(-90deg);
                
                  /* Opera */
                  -o-transform: rotate(-90deg);
                
                  /* Internet Explorer */
                  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
                }
      
              div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU div.style_ItemFlags__2FsAV div.style_iFlags__2jAOm.style_iSale__Iy453 {
                padding-top: 50%;

                border:1px solid  #E10000;
                background-color: #E10000;
              }

                div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU div.style_ItemFlags__2FsAV div.style_iFlags__2jAOm.style_iSale__Iy453 div{
                  -webkit-transform: rotate(-90deg);
    
                  /* Firefox */
                  -moz-transform: rotate(-90deg);
                
                  /* IE */
                  -ms-transform: rotate(-90deg);
                
                  /* Opera */
                  -o-transform: rotate(-90deg);
                
                  /* Internet Explorer */
                  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
                }
      
              div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU div.style_ItemFlags__2FsAV div.style_iFlags__2jAOm.style_iSeason__tDUrc {
                padding-top: 50%;

                border:1px solid  #FF8000;
                background-color: #FF8000;
              }

                div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU div.style_ItemFlags__2FsAV div.style_iFlags__2jAOm.style_iSeason__tDUrc div{
                  -webkit-transform: rotate(-90deg);
    
                  /* Firefox */
                  -moz-transform: rotate(-90deg);
                
                  /* IE */
                  -ms-transform: rotate(-90deg);
                
                  /* Opera */
                  -o-transform: rotate(-90deg);
                
                  /* Internet Explorer */
                  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
                }

      div.style_Container__28A55 div.style_DivNotInStock__3QKGD {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        color: var(--mainWhite);
        background-color: rgba(0, 0, 0, 0.68); 
        height: 100%;
        line-height: 50px;
        font-size: 40px;
        font-weight: 600;
      }

        div.style_Container__28A55 div.style_DivNotInStock__3QKGD div{
          padding-top: 30%;
        }
  }
div.style_Container__2QRbD{
    display: flex;
    /* gap: 10px; */
    justify-content: flex end;
    width: 100%;
}
    
    div.style_Container__2QRbD div.style_Unit__3pePf{
        width: 60px;
        height: 22px;
        font-size: 15px;
        line-height: 21px;
        border-radius: var(--brdRadius);
        border:1px solid var(--mainColor);
        margin-bottom: 5px;
        font-size: 17px;
    }
    
    div.style_Container__2QRbD div.style_Unit__3pePf:hover{
        cursor: pointer;
    }

div.style_Container__2QRbD > * + *{
    margin-right: 5px;
}
div.style_Container__1vjeN{
    /* border: 1px solid red; */
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

    div.style_Container__1vjeN div.style_cancleBtn__Q9Dvp{
        position: absolute;
        font-size: 25px;
        cursor: pointer;
        left: 10px;
        top: 10px;
    }

    div.style_Container__1vjeN div.style_Content__1X6bQ{
        /* border: 1px solid red; */
        width: 80%;
        text-wrap: wrap;
        margin: auto;
        align-text: right;
        word-break: break-word;
    }

div.style_Container__3rwbv{
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -ms-user-select: none;
    user-select: none;
    color: #cc0000;

    /* border: 3px solid red; */
    /* height: 100%;
    width: 100%; */
    position: relative;
}
    div.style_Container__3rwbv div.style_cancleBtn__1bCDd{
        color: #FFf;
        font-size: 35px;
        cursor: pointer;
        text-align: right;
    }
    div.style_Container__3rwbv div.style_cancleBtn__1bCDd{
        margin-right: 2%;
        color: #FFf;
        font-size: 30px;
        cursor: pointer;
        text-align: right;
    }
    div.style_Container__3rwbv div.style_Slider__30wd7{
        /* border: 3px solid white; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 60vh;
    }
    div.style_Container__3rwbv div.style_Slider__30wd7 div.style_RightArrow__1Zb-Z{
            /* border: 2px solid red; */
            display: flex;
            justify-content: center;
            align-items:center;
            height: 100%;
            font-size: 50px;
            margin: auto;
            padding-right: 5%;
            padding-left: 2%;
            color: white;
            cursor: pointer;
        }
    div.style_Container__3rwbv div.style_Slider__30wd7 div.style_Images__2NpA2{
            /* border: 3px solid red; */
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            /* align-items:center; */
            overflow: auto;
        }
    div.style_Container__3rwbv div.style_Slider__30wd7 div.style_LeftArrow__3sND-{
            /* border: 1px solid red; */
            display: flex;
            justify-content: center;
            align-items:center;
            height: 100%;
            width: -webkit-max-content;
            width: -moz-max-content;
            width: max-content;
            font-size: 50px;
            padding-left: 5%;
            padding-right: 2%;
            color: white;
            cursor: pointer;
            draggable: false;
        }
    div.style_Container__3rwbv div.style_Dots__2qE8a{
        /* border: 3px solid green; */
        margin-top: 3%;
        display: flex;
        justify-content: center;
        overflow: hidden;
    }
    div.style_Container__3rwbv div.style_Dots__2qE8a div.style_Dot__gmrbO{
            padding: 8px;
            border-radius: 100%;
        }
    div.style_Container__3rwbv div.style_Dots__2qE8a > * + * {
        margin-right: 2%;
    }

.style_container__1No6B{
    direction: ltr;
    margin-bottom: 5vh;
}

    .style_container__1No6B .style_closeBtn__1dO3c{
        margin: .7vh 0 0 .6vw;
        font-size: 1.3vw;
        cursor: pointer;
        width: -webkit-min-content;
        width: -moz-min-content;
        width: min-content;
    }

    .style_container__1No6B .style_body__1idoB{
        /* border: 1px solid red; */
        margin: auto;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .style_container__1No6B .style_body__1idoB .style_itemImage__18eWY{
            width: 20%;
        }

    .style_container__1No6B .style_body__1idoB .style_itemName__g92qm{
            margin-top: 1vh;
            font-size: 1.3vw;
            font-weight: 600;
        }

    .style_container__1No6B .style_body__1idoB .style_details__2PhPJ{
            /* border: 1px solid red; */
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;
        }

    .style_container__1No6B .style_body__1idoB .style_details__2PhPJ .style_pair__2jeyM{
                /* border: 1px solid red; */
                width: 100%;
            }

    .style_container__1No6B .style_body__1idoB .style_details__2PhPJ .style_pair__2jeyM .style_detail__2fgEo{
                    /* border: 1px solid red; */
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
    
                    margin: 1vw;
                }

    .style_container__1No6B .style_body__1idoB .style_details__2PhPJ .style_pair__2jeyM .style_detail__2fgEo .style_detailTitle__3o5Jt{
                        font-size: 1vw;
                        font-weight: 600;
                    }

    .style_container__1No6B .style_body__1idoB .style_details__2PhPJ .style_pair__2jeyM .style_detail__2fgEo .style_detailContent__Bqd0E{
                        font-size: 1vw;
                    }

    .style_container__1No6B .style_body__1idoB .style_comments__1tBfO{
            /* border: 1px solid red; */
            width: 100%;
            margin-top: 5vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

    .style_container__1No6B .style_body__1idoB .style_comments__1tBfO .style_titleComments__1XZqu{
                font-size: 1vw;
                font-weight: 600;
            }

    .style_container__1No6B .style_body__1idoB .style_comments__1tBfO .style_bodyComments__2-Rtd{
                font-size: 1vw;
                width: 60%;
                word-wrap: break-word;
                text-align: center;
            }

@media (max-width: 700px){
        .style_container__1No6B .style_closeBtn__1dO3c{
            font-size: 3vw;
            margin-left: 1.2vw;
        }

        .style_container__1No6B .style_body__1idoB{
            width: 90%;
        }
            .style_container__1No6B .style_body__1idoB .style_itemName__g92qm{
                font-size: 3vw;
            }
                        .style_container__1No6B .style_body__1idoB .style_details__2PhPJ .style_pair__2jeyM .style_detail__2fgEo .style_detailTitle__3o5Jt{
                            font-size: 1.8vw;
                        }
                        .style_container__1No6B .style_body__1idoB .style_details__2PhPJ .style_pair__2jeyM .style_detail__2fgEo .style_detailContent__Bqd0E{
                            font-size: 1.7vw;
                        }
                .style_container__1No6B .style_body__1idoB .style_comments__1tBfO .style_titleComments__1XZqu{
                    font-size: 2vw;
                }
                .style_container__1No6B .style_body__1idoB .style_comments__1tBfO .style_bodyComments__2-Rtd{
                    font-size: 1.9vw;
                }
}
div.style_ModalContainer__1fpJG{
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

    div.style_ModalContainer__1fpJG div.style_Header__WvYmv{
        /* border: 1px solid blue; */
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
    }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR{
        /* border: 1px solid green; */
        margin: auto;
        width: 90%;
        height: 80%;
        overflow: auto;
        
        display: flex;
        flex-wrap: wrap;
    }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc{
            /* border: 4px solid red; */
            width: 10rem;
            /* height: 15rem; */
            flex: 0 1 50%;
            cursor: pointer;
        }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ{
                /* border: 4px solid purple; */
                margin: auto;
                max-width: 50%;
                position: relative;
            }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ div.style_PriceCircle__3FXJi{
                    position: absolute;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-around;
                    flex-direction: row;
                    top: 0%;
                    right: -5%;
                    border-radius: 50px;
                    color: #FFF;
                    font-family: 'Heebo';
                    font-size: 100%;
                    padding: 7%;
                    padding-bottom: 9%;
                    height: 2.4rem;
     
                }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ div.style_PriceCircle__3FXJi div.style_Agorot__onfFI{
                        margin-bottom: 30%;
                        font-size: .5em;
                        line-height: 1.0rem;
                        height: 0.8rem;
                    }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ div.style_PriceCircle__3FXJi div.style_Shekels__3t2Dj{
                        font-size: 1.3em;
                        line-height: 1.8rem;
                        height: 0.8rem;
                        
                    }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ div.style_PriceCircle__3FXJi div.style_Curr__1SMuP{
                        font-size: 0.8em;
                        line-height: 2rem;
                        height: 0.8rem;
                    }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemName__3fdXN{
                /* border: 1px solid red; */
                margin: auto;
                text-align: center;
                width: 60%;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 1.1rem;
                font-weight: 500;
            }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc:hover{
            opacity: .8;
        }

    div.style_ModalContainer__1fpJG div.style_NoThanksFooter__174W3{
        /* border: 1px solid green; */
        display: flex;
        justify-content: center;
    }

    div.style_ModalContainer__1fpJG div.style_NoThanksFooter__174W3 span{
            /* border: 1px solid red; */
            font-size: 1.8rem;
            color: #7d7d7d;
            text-decoration: underline;
            cursor: pointer;
        }

    div.style_ModalContainer__1fpJG div.style_NoThanksFooter__174W3 span:hover{
            opacity: .7;
        }

@media (max-width: 600px){
        div.style_ModalContainer__1fpJG div.style_Header__WvYmv{
            font-size: 1.5rem;
        }

    
                div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ{
                    max-width: 75%;
                    padding-right: 5%;
                }
                        
                        div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ div.style_PriceCircle__3FXJi div.style_Agorot__onfFI{
                        }
    
                        div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ div.style_PriceCircle__3FXJi div.style_Shekels__3t2Dj{
                        }
                        div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ div.style_PriceCircle__3FXJi div.style_Curr__1SMuP{
                        }
    
                div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemName__3fdXN{

                }

            div.style_ModalContainer__1fpJG div.style_NoThanksFooter__174W3 span{

            }
}
/* @media screen and (min-width: 601px) { */
  div.style_Container__1Wvk8{
    /* border: 2px solid red; */
    justify-self: right;
    flex: 1 1;
    justify-content: space-between;
    align-items: center;
    margin: 5px 5px 0 15px;
    padding: 5px 5px 0 0;
    white-space: nowrap;
    border-radius: var(--brdRadius);
    background-color: var(--mainWhite);
    /* overflow: hidden; */
    overflow: auto;
    height: 77vh;
    min-width: 34vw;
  }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o {
      /* border: 4px solid green; */
      text-align: center;
      display: flex;
      flex-flow: column nowrap;
      align-content: center;
      padding: 10px;
    }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_Summary__3u2O7 {
        display: flex;
  
        /* flex-flow: row-reverse wrap; */
        flex-flow: row-reverse;
        align-self: center;
        align-content: center;
        justify-content: space-between;
        border-radius: 50px;
        height: 50px;
        width: 340px;
        max-width: 100%;
        border-width: 1px;
        border-style: solid;
      }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_Summary__3u2O7 .style_CartSum__2a95R {
          align-self: center;
          padding-top: 6px;
        }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_Summary__3u2O7 .style_CartSum__2a95R .style_CartSumSh__JtfpG {
            font-size: 16px;
          }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_Summary__3u2O7 .style_CartSum__2a95R .style_CartSumVal___dFh6 {
            font-size: 26px;
          }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_Summary__3u2O7 .style_CartText__15dvp {
          align-self: center;
          text-align: right;
          margin-right: 15px;
          font-size: 16px;
          line-height: 18px;
        }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_Summary__3u2O7 .style_CartWrapper__1YetC {
          display: flex;
          height: 100%;
        }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_Summary__3u2O7 .style_CartWrapper__1YetC .style_CartImg__3tMDl {
            width: 26px;
            height: 26px;
            margin: 11px;
            margin-left: 15px;
            /* height: 80px; */
          }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_Summary__3u2O7 .style_CartWrapper__1YetC .style_ItemsInCart__3uZa0 {
            color: var(--mainWhite);
            position: relative;
            top: 17px;
            height: 21px;
            line-height: 21px;
            min-width: 21px;
            left: -23px;
            font-size: 14px;
            border-radius: var(--brdRadius);
            padding-left: 3px;
            padding-right: 3px;
            font-weight: 600;
          }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_CartItems__1eCle {
        /* border: 2px solid red; */
        margin-top: 20px;
        overflow: auto;
        max-height: 100%;
        padding: 0 20px 0 20px;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
      }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_CartItems__1eCle .style_CartItemsEmpty__1oUws {
          margin-top: 130px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          /* gap: 6px; */
          color: var(--mainColor);
          top: 15vh;
        }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_CartItems__1eCle .style_CartItemsEmpty__1oUws span:nth-child(1) {
            margin-bottom: 6px;
            font-size: 27px;
            font-weight: 600;
          }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_CartItems__1eCle .style_CartItemsEmpty__1oUws span:nth-child(2) {
            margin-bottom: 6px;
            font-size: 25px;
          }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_CartItems__1eCle .style_CartItemsEmpty__1oUws img.style_BasketImg__2GcGA {
            width: 80px;
          }
  div.style_Container__1Wvk8 div.style_PaymentButtons__3muah{
      width: 100%;
      height: -webkit-max-content;
      height: -moz-max-content;
      height: max-content;
      display: flex;
      flex-direction: column;
      justify-content: end;
    }



@media (max-width: 850px){
  div.style_Container__1Wvk8{
    margin: 5px 5px 0 5px;
    padding: 5px 5px 0 5px;
  }
}
div.style_Container__3l8I7{
    /* border: 2px solid red; */
    display: flex;
    border-bottom: 2px solid #D3D3D3;
    margin-top: 10px;
    justify-content: center;
    z-index: 1;
}

    div.style_Container__3l8I7 div.style_RightElements__2BG9Q{
        /* border: 5px solid blue; */
        z-index: 1;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
    }

    div.style_Container__3l8I7 div.style_RightElements__2BG9Q div.style_ItemCheckbox__1rzXe{
        }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B{
        /* border: 5px solid red; */
        z-index: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 5px;
        margin-right: 5px;
    }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_ImagFlagDetalisWrapper__1D88R{
            /* border: 1px solid blue; */
            overflow: hidden;
            display: flex;
            align-items: center;
        }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_ImagFlagDetalisWrapper__1D88R div.style_ImageAndFlag__1WN48{
                /* border: 1px solid red; */
                padding: 0;
                margin: 0;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_ImagFlagDetalisWrapper__1D88R div.style_ImageAndFlag__1WN48 .style_ItemImage__NDe1P{
                    /* border: 1px solid red; */
                    max-width:  90px;
                }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_ImagFlagDetalisWrapper__1D88R div.style_ImageAndFlag__1WN48 div.style_ItemFlags__SpN14 {
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_ImagFlagDetalisWrapper__1D88R div.style_ImageAndFlag__1WN48 div.style_ItemFlags__SpN14 div.style_iFlags__CcvwG {
                        color: var(--mainWhite);
                        width: 100%;
                        max-width: 200px;
                        border-radius: 30px;
                        height: 20px;
                        font-size: 12px;
                        font-weight: 500;
                    }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_ImagFlagDetalisWrapper__1D88R div.style_ImageAndFlag__1WN48 div.style_ItemFlags__SpN14 div.style_iFlags__CcvwG.style_iNew__UECNM {
                            border:1px solid  #FF8000;
                            background-color: #FF8000;
                        }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_ImagFlagDetalisWrapper__1D88R div.style_ImageAndFlag__1WN48 div.style_ItemFlags__SpN14 div.style_iFlags__CcvwG.style_iSale__2E4pV {
                            border:1px solid  #E10000;
                            background-color: #E10000;
                        }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_ImagFlagDetalisWrapper__1D88R div.style_ImageAndFlag__1WN48 div.style_ItemFlags__SpN14 div.style_iFlags__CcvwG.style_iSeason__2BblY {
                            border:1px solid  #FF8000;
                            background-color: #FF8000;
                        }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_ImagFlagDetalisWrapper__1D88R div.style_ItemDetails__2tagh{
                /* border: 1px solid red; */
                height: 100%;
                max-width: 70%;
                padding: 0;
                margin: 0;
                margin-right: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                text-align: right;
                overflow: hidden;
                overflow-x: auto;
                line-height: 120%;
            }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_ImagFlagDetalisWrapper__1D88R div.style_ItemDetails__2tagh div.style_ItemName__1C43R{
                    /* border: 1px solid red; */
                    font-size: 18px;
                    font-weight: 500;
                    max-width: 180px;
                    overflow: hidden;
                    text-overflow: ellipsis;  
                    cursor: default;
                }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_ImagFlagDetalisWrapper__1D88R div.style_ItemDetails__2tagh div.style_ItemPrice__vRLsE{
                    font-size: 18px;
                    font-family: 'Heebo', sans-serif;
                    font-weight: 500;
                    cursor: default;
                }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_ImagFlagDetalisWrapper__1D88R div.style_ItemDetails__2tagh div.style_ItemQuantity__1uWEj{
                    /* border: 1px solid red; */
                    font-size: 18px;
                    font-weight: 500;
                    text-align: right;
                    cursor: default;
                }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_Comment__33HGI{
            /* border: 1px solid blue; */
            display: flex;
            align-items: center;
            margin-top: 10px;
            width: 100%;
        }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_Comment__33HGI div.style_Title__16UQc{
                font-size: 13px;
                text-decoration: underline;
                cursor: pointer;
            }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_Comment__33HGI div.style_InputText__1Vjnh{
                width: 100%;
            }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_Comment__33HGI div.style_InputText__1Vjnh input{
                    width: 100%;
                    font-size: 13px;
                    outline: none;
                    border: none;
                    font-family: 'Heebo', sans-serif;
                }

    div.style_Container__3l8I7 div.style_LeftElements__1a_nq{
        /* border: 5px solid blue; */
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;

    }

    div.style_Container__3l8I7 div.style_LeftElements__1a_nq div.style_MoreInfo__1BEWk{
            /* border: 1px solid red; */
            margin-bottom: auto;
            width: 100%;
            direction : ltr;
        }

    div.style_Container__3l8I7 div.style_LeftElements__1a_nq div.style_MoreInfo__1BEWk div.style_MoreInfoTooltip__3X6en{
                /* border: 1px solid red; */
                width: -webkit-max-content;
                width: -moz-max-content;
                width: max-content;
                font-size: 10px;
                text-decoration: underline;
                text-align: left;
            }

    div.style_Container__3l8I7 div.style_LeftElements__1a_nq div.style_MoreInfo__1BEWk div.style_MoreInfoTooltip__3X6en .style_Tooltip__iOdLT{
                    max-width: 400px !important;
                    text-align: right;
                    white-space: pre-wrap !important;
                }

    div.style_Container__3l8I7 div.style_LeftElements__1a_nq div.style_MoreInfo__1BEWk div.style_MoreInfoTooltip__3X6en > .style_label__3Vspw:hover{
                cursor: help;
                opacity: .6;
            }

    div.style_Container__3l8I7 div.style_LeftElements__1a_nq div.style_Buttons__1MZ5Y{
            /* border: 2px solid green; */
            margin-bottom: auto;
        }

    div.style_Container__3l8I7 div.style_LeftElements__1a_nq div.style_Buttons__1MZ5Y button{
                border-radius: 25px;
                width: 100px;
                height: 28px;
                font-size: 14px;
                /* font-weight: 500; */
                color: #FFF;
                font-family: 'Heebo', sans-serif;
                background-color: #FFFFFF;
                outline: none;
                cursor: pointer;
            }

    div.style_Container__3l8I7 div.style_LeftElements__1a_nq div.style_Buttons__1MZ5Y div.style_EditButton__2Bas2{
                margin-bottom: 5px;
            }

    div.style_Container__3l8I7 div.style_LeftElements__1a_nq div.style_Buttons__1MZ5Y div.style_RemoveButton__1j-Y8{
            }

@media (max-width: 500px){

                    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_ImagFlagDetalisWrapper__1D88R div.style_ImageAndFlag__1WN48 .style_ItemImage__NDe1P{
                        width:  60px;
                    }
                        div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_ImagFlagDetalisWrapper__1D88R div.style_ImageAndFlag__1WN48 div.style_ItemFlags__SpN14 div.style_iFlags__CcvwG{
                            height: 18px;
                        }
                    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_ImagFlagDetalisWrapper__1D88R div.style_ItemDetails__2tagh div.style_ItemName__1C43R{
                        max-width: 100px;
                        font-size: 14px;
                    }
                    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_ImagFlagDetalisWrapper__1D88R div.style_ItemDetails__2tagh div.style_ItemPrice__vRLsE{
                        font-size: 12px;
                    }

                    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_ImagFlagDetalisWrapper__1D88R div.style_ItemDetails__2tagh div.style_ItemQuantity__1uWEj{
                        font-size: 12px;
                    }
                div.style_Container__3l8I7 div.style_LeftElements__1a_nq div.style_Buttons__1MZ5Y button{
                    height: 24px;
                    width: 80px;
                }
}
div.style_Container__3oDAb{
    /* border: 4px solid brown; */
    height: 100%;
    overflow: auto;
}
    
    div.style_Container__3oDAb div.style_OptionsDescription__3pDJ5{
        font-size: 14px;
        margin-bottom: 5px;
    }
    
    div.style_Container__3oDAb div.style_ItemOptionList__2yjj7{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* gap: 10px; */
        padding: 0 15% 0 15%;
    }
    
    div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 div.style_ItemOption__3hBfo{
            display: flex;
            justify-content: space-between;
            font-weight: 500;
            font-size: 20px;
        }
    
    div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 div.style_ItemOption__3hBfo label, div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 div.style_ItemOption__3hBfo input{
                margin-top: 1px;
                /* vertical-align: top; */
            }
    
    div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 > * + *{
        margin-top: 10px;
    }



@media (-webkit-max-device-pixel-ratio: 1.1458333333333333), (max-resolution: 110dpi){
        div.style_Container__3oDAb div.style_OptionsDescription__3pDJ5{
            font-size: 18px;
        }
            div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 div.style_ItemOption__3hBfo{
                font-weight: 500;
                font-size: 20px;
            }
}

@media (-webkit-min-device-pixel-ratio: 1.3541666666666667), (min-resolution: 130dpi){
        div.style_Container__3oDAb div.style_OptionsDescription__3pDJ5{
            font-size: 12px;
        }
            div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 div.style_ItemOption__3hBfo{
                font-weight: 500;
                font-size: 12px;
            }

                div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 div.style_ItemOption__3hBfo input{
                    /* width: 10px; */
                }
}

@media (-webkit-min-device-pixel-ratio: 1.5625), (min-resolution: 150dpi){
        div.style_Container__3oDAb div.style_OptionsDescription__3pDJ5{
            font-size: 10px;
        }
            div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 div.style_ItemOption__3hBfo{
                font-weight: 500;
                font-size: 10px;
            }
}

@media (max-width: 850px){
        div.style_Container__3oDAb div.style_OptionsDescription__3pDJ5{
            font-size: 18px;
        }
            div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 div.style_ItemOption__3hBfo{
                font-weight: 500;
                font-size: 17px;
            }
                    div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 div.style_ItemOption__3hBfo div.style_WrapBtnAndName__3tFdI input{
                        width: 20px;
                    }
}
div.style_Container__3ty64{
    background: #D6D6D6 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    margin-left: 30px;
    margin-right: 30px;
    padding: 20px 0 20px 0;
    cursor: pointer;
}


@media (max-width: 500px){
    div.style_Container__3ty64{
        overflow: auto;
        font-size: 11px;
        margin-left: 5px;
        margin-right: 5px;
        padding: 10px 0 10px 0;
    }
}
/* @media screen and (min-width: 601px) { */

div.style_Container__1q1yQ{
    /* border: 4px solid brown; */
    height: 100%;
    overflow: auto;
}

div.style_Container__1q1yQ div.style_ToppingsDescription__3EzDt{
        font-size: 14px;
        margin-bottom: 5px;
    }

div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* gap: 10px; */
        padding: 0 15% 0 15%;
    }

div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC{
            display: flex;
            justify-content: space-between;
            font-weight: 500;
            font-size: 20px;
        }

div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 > * + *{
        margin-top: 10px;
    }

@media (-webkit-max-device-pixel-ratio: 1.1458333333333333), (max-resolution: 110dpi){
        div.style_Container__1q1yQ div.style_ToppingsDescription__3EzDt{
            font-size: 18px;
        }
            div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC{
                font-weight: 500;
                font-size: 20px;
            }
}

@media (-webkit-min-device-pixel-ratio: 1.3541666666666667), (min-resolution: 130dpi){
        div.style_Container__1q1yQ div.style_ToppingsDescription__3EzDt{
            font-size: 12px;
        }
            div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC{
                font-weight: 500;
                font-size: 12px;
            }

                div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC input{
                    width: 10px;
                }
}

@media (-webkit-min-device-pixel-ratio: 1.5625), (min-resolution: 150dpi){
        div.style_Container__1q1yQ div.style_ToppingsDescription__3EzDt{
            font-size: 10px;
        }
            div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC{
                font-weight: 500;
                font-size: 10px;
            }
}
/* } */

@media (max-width: 850px){
        div.style_Container__1q1yQ div.style_ToppingsDescription__3EzDt{
            font-size: 18px;
        }
                    div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC div.style_WrapBtnAndName__1u6JR input{
                        width: 20px;
                    }
                    div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC div.style_WrapBtnAndName__1u6JR .style_ItemToppingName__Xmcqt{
                        font-size: 17px;
                    }
                div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC .style_ItemToppingPrice__3Mn3S{
                    font-size: 17px;
                }
}
/* @media screen and (min-width: 601px) { */
/* @media (-webkit-max-device-pixel-ratio: 1) { */

div.style_Container__3q394{
    /* border: 4px solid brown; */
    position: relative;
    height: 100%;
    overflow: auto;
}

div.style_Container__3q394 div.style_ToppingsDescription__2MbU0{
        font-size: 14px;
        margin-bottom: 5px;
    }

div.style_Container__3q394 div.style_MaxToppingsError__dtly0{
        font-size: 12px;
        color: #FF0000;
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        top: -50px;
    }

div.style_Container__3q394 div.style_PizzaContainer__NH-II{
        /* border: 2px solid green; */
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
    }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU{
            display: flex;
            flex-direction: column;
            direction: ltr;
            position: absolute;
            right: 0;
            top: 0;
            max-height: 100%;
            overflow: auto;
            font-size: 12px;
            width: 110px;
            z-index: 20;
        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedProductOption__2JHTz{
                text-align: right;
                color: #FFF;
                border-radius: 20px 0 0 20px;
                padding-left: 13px;
                padding-right: 13px;
            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I{
                display: flex;
                flex-direction: column;

                
            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingToEdit__1dvWG{
                    color: #FFF;
                    display: flex;
                    border-radius: 20px 0 0 20px;
                    justify-content: space-between;
                    align-items: center;
                    cursor: default;
                }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingToEdit__1dvWG div.style_ToppingNameAndPrice__30kIB{
                        text-align: right;
                    }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingToEdit__1dvWG div.style_ToppingNameAndPrice__30kIB div.style_ToppingName__16znU{
                            width: 60px;
                            overflow: hidden !important;
                            text-overflow: ellipsis !important;
                        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingToEdit__1dvWG div.style_ToppingNameAndPrice__30kIB div.style_ToppingName__16znU span.style_ToppingXRemover__1lD8t{
                                padding-left: 3px;
                                cursor: pointer;
                            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingToEdit__1dvWG div.style_ToppingNameAndPrice__30kIB div.style_ToppingName__16znU span.style_ToppingXRemover__1lD8t:hover{
                            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingToEdit__1dvWG div.style_ToppingNameAndPrice__30kIB div.style_ToppingPrice__2F0LM{
                            padding-right: 13px;
                        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingToEdit__1dvWG div.style_ToppingImgPositions__tN4X2{
                    }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingToEdit__1dvWG:hover{
                    /* cursor: default; */
                    /* background-color: rgba(255,0,0,0.5) !important; */
                }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I > * + * {
                margin-top: 5px;
            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU > * + *{
            margin-top: 5px;
        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9{
            /* border: 1px solid red; */
            position: relative;
        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_PizzaCenter__EayQ7{
                /* background-color: rgb(0,0,255); */
                /* border: 4px solid black; */
                z-index: 11;
                position: absolute;
                border-radius: 50%;
                height: 28px;
                width: 30px;
                top: 102px;
                right: 100px;
            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_TopLeftQuarter__1s8lm{
                /* background-color: rgba(123,114,23,0.5); */
                z-index: 10;
                position: absolute;
                padding: 55px;
                border-top-left-radius: 170px;
                top: 0;
                left: 0;
            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_TopLeftQuarter__1s8lm img{
                    transform: rotate(-90deg);
                    position: absolute;
                    width: 112px;
                    border-top-right-radius: 170px;
                    top: 4px;
                    left: 0;
                }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_TopRightQuarter__WRNgz{
                /* background-color: rgba(123,114,23,0.5); */
                z-index: 10;
                position: absolute;
                padding: 55px;
                border-top-right-radius: 170px;
                top: 0;
                right: 0;
            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_TopRightQuarter__WRNgz img{
                    position: absolute;
                    width: 112px;
                    border-top-right-radius: 170px;
                    top: 0;
                    right: 0;
                }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_BottomLeftQuarter__2oEOk{
                /* background-color: rgba(123,114,23,0.5); */
                z-index: 10;
                position: absolute;
                padding: 55px;
                border-bottom-left-radius: 170px;
                bottom: 7px;
                left: 0;
            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_BottomLeftQuarter__2oEOk img{
                    transform: rotate(180deg);
                    position: absolute;
                    width: 112px;
                    border-top-right-radius: 170px;
                    bottom: 4px;
                    left: 0;
                }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_BottomRightQuarter__3XEdh{
                /* background-color: rgba(123,114,23,0.5); */
                z-index: 10;
                position: absolute;
                padding: 55px;
                border-bottom-right-radius: 170px;
                bottom: 7px;
                right: 0;
            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_BottomRightQuarter__3XEdh img{
                    transform: rotate(90deg);
                    position: absolute;
                    width: 112px;
                    border-top-right-radius: 170px;
                    bottom: 4px;
                    right: 0;
                }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_ChoiceIcon__3Rosw{
            /* border: 1px solid blue; */
            position: absolute;
        }

div.style_Container__3q394 div.style_ItemToppingList__tfcMs{
        /* border: 1px solid blue; */
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 70%;
        overflow: auto !important;
    }

div.style_Container__3q394 div.style_ItemToppingList__tfcMs div.style_ItemTopping__1H0fO{
            /* border: 1px solid red; */
            display: flex;
            justify-content: space-between;
            font-weight: 500;
            font-size: 15px;
        }

div.style_Container__3q394 div.style_ItemToppingList__tfcMs > * + *{
        margin-top: 10px;
    }
/* } */


/* @media (-webkit-min-device-pixel-ratio: 1) { */
div.style_Container__1j-Fu{
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    /* border: 4px solid #F6F2F2; */
    margin-top: 10px;
    border-radius: 15px;
    overflow: auto;
    /* } */
}
/* box-shadow: 0 .1vh .4vw rgba(0,0,0,.1); */
div.style_Container__1j-Fu div.style_TopContent__2rHll{
        /* border: 1px solid red; */
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
    }
div.style_Container__1j-Fu div.style_TopContent__2rHll div.style_CloseButton__3gg0h button{
                font-size: 25px;
                font-weight: 600;
                background-color: unset;
                cursor: pointer;
                border: none;
                outline: none;
            }
div.style_Container__1j-Fu div.style_TopContent__2rHll div.style_ItemName__31kMC{
            cursor: default;
            padding-right: 25px; 
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis; 

            flex: 1 1;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
        }
/* div.BodyContent{ */
div.style_Container__1j-Fu form{
            /* border: 4px solid red; */
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 90% !important;
        }
div.style_Container__1j-Fu form div.style_FormButtons__2DiNw{
                /* border: 4px solid blue; */
                margin-top: 20px;
                height: 22%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }
div.style_Container__1j-Fu form div.style_FormButtons__2DiNw div.style_PrevAndSubmitBtns__2-hY2{
                    display: flex;
                    flex-direction: column;
                    /* gap: 5px; */
                }
/* div.PrevAndSubmitBtns > * + *{
                    margin-top: 5px;
                } */
div.style_Container__1j-Fu form div.style_FormButtons__2DiNw button{
                    /* color: #DC143C; */
                    border-radius: 25px;
                    width: 280px;
                    max-width: 100%;
                    font-size: 25px;
                    font-weight: 500;
                    height: 40px;
                    font-family: 'Heebo', sans-serif;
                    background-color: unset;
                    /* border: 1px solid #DC143C; */
                    cursor: pointer;
                    outline: none;
                }

@media (-webkit-max-device-pixel-ratio: 1.1458333333333333), (max-resolution: 110dpi){
            div.style_Container__1j-Fu div.style_TopContent__2rHll div.style_ItemName__31kMC{
                font-size:25px;
            }
}

@media (-webkit-min-device-pixel-ratio: 1.3541666666666667), (min-resolution: 130dpi){
            div.style_Container__1j-Fu div.style_TopContent__2rHll div.style_ItemName__31kMC{
                font-size:15px;
            }
}

@media (-webkit-min-device-pixel-ratio: 1.5625), (min-resolution: 150dpi){
            div.style_Container__1j-Fu div.style_TopContent__2rHll div.style_ItemName__31kMC{
                font-size:13px;
            }
}

@media (max-width: 850px){
            div.style_Container__1j-Fu div.style_TopContent__2rHll div.style_ItemName__31kMC{
                font-size:25px;
            }
}
div.style_Container__2jzhW{
    /* border: 4px solid brown; */
    height: 100%;
    overflow: auto;
}
    
    div.style_Container__2jzhW div.style_OptionsDescription__24lc1{
        font-size: 14px;
        margin-bottom: 5px;
    }
    
    div.style_Container__2jzhW div.style_ItemOptionList__1QCfv{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 15% 0 15%;
    }
    
    div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe{
            /* border: 4px solid pink; */
            border-bottom: 2px solid #D3D3D3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 500;
            font-size: 20px;
        }
    
    div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe div.style_WrapCheckImg__3wXPP{
                display: flex;
                width: 50%;
            }
    
    div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe div.style_WrapCheckImg__3wXPP input{
                    cursor: pointer;
                }
    
    div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe div.style_WrapCheckImg__3wXPP div.style_itemImage__1Y1wm{
                    /* border: 1px solid blue; */
                    max-width: 6rem;
                }
    
    div.style_Container__2jzhW div.style_ItemOptionList__1QCfv label.style_ItemOptionName__3hTdx {
            width: 50%;
            white-space: normal;
            text-align: left;
        }
    
    div.style_Container__2jzhW div.style_ItemOptionList__1QCfv > * + *{
        margin-top: 10px;
    }



@media (-webkit-max-device-pixel-ratio: 1.1458333333333333), (max-resolution: 110dpi){
        div.style_Container__2jzhW div.style_OptionsDescription__24lc1{
            font-size: 18px;
        }
            div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe{
                font-weight: 500;
                font-size: 20px;
            }
}

@media (-webkit-min-device-pixel-ratio: 1.3541666666666667), (min-resolution: 130dpi){
        div.style_Container__2jzhW div.style_OptionsDescription__24lc1{
            font-size: 12px;
        }
            div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe{
                font-weight: 500;
                font-size: 12px;
            }

                div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe input{
                    /* width: 10px; */
                }
}

@media (-webkit-min-device-pixel-ratio: 1.5625), (min-resolution: 150dpi){
        div.style_Container__2jzhW div.style_OptionsDescription__24lc1{
            font-size: 10px;
        }
            div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe{
                font-weight: 500;
                font-size: 10px;
            }
}

@media (max-width: 850px){
        div.style_Container__2jzhW div.style_OptionsDescription__24lc1{
            font-size: 18px;
        }
            div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe{
                font-weight: 500;
                font-size: 17px;
            }
                    div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe div.style_WrapBtnAndName__1ivam input{
                        width: 20px;
                    }
}
/* @media screen and (min-width: 601px) { */
    div.style_Container__1UF_F{
        /* border: 1px solid blue; */
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        overflow: auto;
        overflow-x: hidden;
    }
    div.style_Container__1UF_F div.style_CloseButton__1nVnb {

            /* button:hover{
                animation-name: spin;
                animation-duration: 1500ms;
                animation-iteration-count: 1.07;
                animation-timing-function: ease-in-out; 
            }

            @keyframes spin {
                from {
                    transform:rotate(0deg);
                }
                to {
                    transform:rotate(360deg);
                }
            } */
        }
    div.style_Container__1UF_F div.style_CloseButton__1nVnb button{
                position: absolute;
                width: 40px;
                font-size: 25px;
                font-weight: 900;
                left: 6px;
                top: 7px;
                background-color: unset;
                color: #520E00;
                cursor: pointer;
                border: none;
                outline: none;
            }
    div.style_Container__1UF_F div.style_StepDescription__3CffU{
            margin-top: 20px;
            font-size: 20px;
            font-weight: 700;
            color: #270001;
        }
    div.style_Container__1UF_F div.style_ItemNumber__3L2fy{
            /* margin-top: 10px; */
            font-size: 20px;
            font-weight: 630;
            color: #270001;
        }
    div.style_Container__1UF_F div.style_StepsBar__3qAs2{
            /* border: 1px solid red; */
            margin: auto;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            /* font-family: Consolas; */
            font-weight: 400;
            color: #dcdcdc;
            width: -webkit-max-content;
            width: -moz-max-content;
            width: max-content;
            word-wrap: break-word;
            overflow: auto;
            
        }
    div.style_Container__1UF_F div.style_StepsBar__3qAs2 div.style_StepBar__2Hz-v{
                /* color: #dcdcdc; */
                width: 1.1rem;
                height: 100%;
                /* border: 1px solid #dcdcdc; */
                border-radius: var(--brdRadius);
            }
    div.style_Container__1UF_F div.style_LevelDescription__3GeuC{
            font-size: 20px;
            margin-top: 15px;
            margin-bottom: 15px;
            font-weight: 600;
        }
    div.style_Container__1UF_F div.style_Buttons__2xW7s{
            display: flex;
            margin-top: 25px;
            margin-bottom: 15px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

        }
    /* gap: 8px; */
    div.style_Container__1UF_F div.style_Buttons__2xW7s button{
                color: #DC143C;
                border-radius: 7px;
                width: 70px;
                background-color: unset;
                border: 1px solid #DC143C;
                outline: none;
                /* margin-bottom: 25px; */
            }
    div.style_Container__1UF_F div.style_Buttons__2xW7s button:hover{
                background-color: rgba(255, 0, 0, 0.05);
                cursor: pointer;
            }
    div.style_Container__1UF_F div.style_Buttons__2xW7s > * + * {
            margin-top: 8px;
        }
/* } */
/* @media screen and (min-width: 601px) { */
    div.style_Container__3MKo3{
        display: flex;
        /* border: 1px solid #e6e6e6; */
        border-radius: 20px;
        margin: 20px;
        padding: 10px;
        padding-top: 10px;
    }
    div.style_Container__3MKo3 div.style_RightElements__1xRp8{
            /* border: 1px solid blue; */
            width: 30%;
        }
    div.style_Container__3MKo3 div.style_RightElements__1xRp8 div.style_Checked__gSv9j{
                display: flex;
                justify-content: flex-start;
            }
    div.style_Container__3MKo3 div.style_MidElements__23Qkv{
            /* border: 1px solid green; */
            width: 55%;
            display: flex;
            flex-direction: column;
            position: relative;
        }
    div.style_Container__3MKo3 div.style_MidElements__23Qkv div.style_ItemName__21-BI{
                position: absolute;
                top: 0;
                font-size: 15px;
                font-weight: 500;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 200px;
                text-align: right;
            }
    div.style_Container__3MKo3 div.style_MidElements__23Qkv div.style_ItemButtons__1lkg7{
                display: flex;
                position: absolute;
                bottom: 0;
            }
    /* gap: 10px; */
    div.style_Container__3MKo3 div.style_MidElements__23Qkv div.style_ItemButtons__1lkg7 div.style_ItemUnitsBtn__3GUSc{
                    /* color: #DC143C; */
                    border-radius: 10px;
                    width: 60px;
                    background-color: #FFFFFF;
                    /* border: 1px solid #DC143C; */
                    outline: none;
                }
    div.style_Container__3MKo3 div.style_MidElements__23Qkv div.style_ItemButtons__1lkg7 div.style_ItemAddBtn__39TnR{
                    /* color: #DC143C; */
                    border-radius: 10px;
                    width: 85px;
                    background-color: #FFFFFF;
                    /* border: 1px solid #DC143C; */
                    outline: none;
                    cursor: pointer;
                }
    div.style_Container__3MKo3 div.style_MidElements__23Qkv div.style_ItemButtons__1lkg7 > * + * {
                margin-right: 10px;
            }
    div.style_Container__3MKo3 div.style_LeftElements__1P8Jn{
            width: 15%;
        }
    div.style_Container__3MKo3 div.style_LeftElements__1P8Jn div.style_MoreInfoTooltip__2jlcM{
                font-size: 14px;
                text-decoration: underline;
                cursor: help;
            }
    div.style_Container__3MKo3 div.style_LeftElements__1P8Jn div.style_MoreInfoTooltip__2jlcM .style_Tooltip__1bQyi{
                    max-width: 400px !important;
                    text-align: right;
                    white-space: pre-wrap !important;
                }
    div.style_Container__3MKo3 div.style_LeftElements__1P8Jn div.style_MoreInfoTooltip__2jlcM > .style_label__2Akmh:hover{
                opacity: .6;
            }
    div.style_Container__3MKo3:hover{
        background-color: #F9F9F9;
    }
/* } */

@media (max-width: 550px){
            div.style_Container__3MKo3 div.style_MidElements__23Qkv div.style_ItemName__21-BI{
                font-size: 12px;
            }
            div.style_Container__3MKo3 div.style_LeftElements__1P8Jn div.style_MoreInfoTooltip__2jlcM{
                font-size: 10px;
            }
}
div.style_Container__2pThQ{
    justify-content: center;
    position: relative;
    overflow: auto;
}

    div.style_Container__2pThQ div.style_CloseButton__293-7 {
        margin-bottom: 50px;
    }

    div.style_Container__2pThQ div.style_CloseButton__293-7 button{
            position: absolute;
            width: 40px;
            font-size: 25px;
            font-weight: 900;
            left: 6px;
            top: 7px;
            background-color: unset;
            color: #000;
            cursor: pointer;
            border: none;
            outline: none;
        }

    div.style_Container__2pThQ div.style_CartItemName__3u80v{
        margin-top: 20px;
        font-size: 20px;
        font-weight: 700;
        color: #270001;
    }

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd{
        margin-left: 7%;
        margin-right: 7%;
        margin-top: 2%;
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #D3D3D3;
    }

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_RightElements__rdCnv{
            display: flex;
            margin-bottom: 5px;
        }

    /* ItemImage{
                img{
                    width: 100px;
                } 
            } */

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_RightElements__rdCnv div.style_ItemDetails__VPF5H{
                margin: auto;
                display: flex;
                flex-direction: column;
                grid-gap: 3vh;
                gap: 3vh;
                margin-right: 25px;
            }

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_RightElements__rdCnv div.style_ItemDetails__VPF5H div.style_ItemName__384ys{
                    font-size: 15px;
                    font-weight: 500;
                }

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_RightElements__rdCnv div.style_ItemDetails__VPF5H div.style_ItemQuantity__34KpE{
                    font-size: 13px;
                    font-weight: 700;
                    text-align: right;
                }

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_LeftElements__2qtwS{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            position: relative;
            grid-gap: 1vh;
            gap: 1vh;
        }

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_LeftElements__2qtwS div.style_MoreInfoTooltip__2lTJv{
                position: absolute;
                flex: 1 1;
                top: 0px;
                left: 0;
                font-size: 10px;
                text-decoration: underline;
                /* color: #B30C00; */
                cursor: help;
            }

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_LeftElements__2qtwS div.style_MoreInfoTooltip__2lTJv .style_Tooltip__2N7eE{
                    max-width: 400px !important;
                    text-align: right;
                    white-space: pre-wrap !important;
                }

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_LeftElements__2qtwS div.style_MoreInfoTooltip__2lTJv *:hover{
                    opacity: .6;
                }

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_LeftElements__2qtwS button{
                color: #DC143C;
                border-radius: 10px;
                width: 100px;
                background-color: #FFFFFF;
                border: 1px solid #DC143C;
                outline: none;
                cursor: pointer;
            }

@media (max-width: 440px){
                /* ItemImage{
                    img{
                        width: 70px;
                    } 
                } */
                    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_RightElements__rdCnv div.style_ItemDetails__VPF5H div.style_ItemName__384ys{
                        font-size: 13px;
                    }
                div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_LeftElements__2qtwS button{
                    width: 80px;
                    font-size: 11px;
                }
}
/* @media screen and (min-width: 601px) { */
div.style_Container__3siyF{
    display: flex;
    justify-content: center;
    position: relative;
}
div.style_Container__3siyF div.style_PaymentBtns__3M4X-{
        margin: 0;
        display: flex;
        flex-direction: column;
        width: 60%;
    }
/* gap: 1vh; */
div.style_Container__3siyF div.style_PaymentBtns__3M4X- div{
            font-family: 'Heebo', sans-serif;
            font-size: 11px;
            height: 40px;
            font-weight: 400;
            border-radius: 30px;
            /* padding-top: 5px; */
            /* padding-bottom: 5px; */
            /* padding: 5px; */
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }
div.style_Container__3siyF div.style_PaymentBtns__3M4X- div:nth-child(1) span{
                font-weight: 700;
                text-decoration: underline;
                background-color: unset;
            }
div.style_Container__3siyF div.style_PaymentBtns__3M4X- div:nth-child(2){
            padding-top: 2px;
            padding-bottom: 2px;
            font-size: 20px;
            font-weight: 400;
            color: #FFFFFF;
        }
div.style_Container__3siyF div.style_PaymentBtns__3M4X- div:nth-child(3) {
            height: 20px;
        }
div.style_Container__3siyF div.style_PaymentBtns__3M4X- div:nth-child(3):hover {
            cursor: default;
        }
div.style_Container__3siyF div.style_PaymentBtns__3M4X- > * + *{
        margin-top: 1vh;
    }
/* } */

@media (-webkit-min-device-pixel-ratio: 1.4583333333333333), (min-resolution: 140dpi){
        div.style_Container__3siyF div.style_PaymentBtns__3M4X-{
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 55%;
        }
            /* gap: 1vh; */
            
            div.style_Container__3siyF div.style_PaymentBtns__3M4X- div{
                font-size: 10px;
                font-weight: 400;
                height: 35px;
                border-radius: 20px;
                padding-top: 5px;
                padding-bottom: 1px;
            }
            /* height: 50%; */

            div.style_Container__3siyF div.style_PaymentBtns__3M4X- div:nth-child(2){
                font-size: 17px;
            }
        
        div.style_Container__3siyF div.style_PaymentBtns__3M4X- > * + *{
            margin-top: 1vh;
        }
}
div.style_Container__FAdkl{
    background-color: rgb(223,223,223,0.97);
    position: fixed;
    bottom: 19vh;
    z-index: 10000;
    padding: 40px;
    border-radius: 10px;
}

    div.style_Container__FAdkl div.style_CouponTitle__nHkmb{
        margin-bottom: 10px;
        font-size: 14px;
    }

    div.style_Container__FAdkl div.style_CouponInput__1CM7G{
        margin-bottom: 20px;
    }

    div.style_Container__FAdkl div.style_CouponInput__1CM7G input{
            text-align: center;
            background-color: unset;
            border: none;
            border-bottom: 1px solid #a5a5a5;
            /* padding: 5px 10px; */
            font-size: 20px;
            outline: none;
        }

    div.style_Container__FAdkl div.style_ErrorMsg__TuuVl{
        position: absolute;
        top: 46%;
        right: 35%;
        font-size: 11px;
        color: #FF0000;
    }

    div.style_Container__FAdkl div.style_CouponBtns__ETQ29{
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* gap: 1vw; */
        margin-top: 40px;
    }

    div.style_Container__FAdkl div.style_CouponBtns__ETQ29 button:nth-child(1){
            color: gray;
            border-radius: 30px;
            width: 100px;
            height: 30px;
            background-color: unset;
            border: 1px solid gray;
            outline: none;
        }

    div.style_Container__FAdkl div.style_CouponBtns__ETQ29 button:nth-child(2){
            color: white;
            border-radius: 30px;
            width: 100px;
            height: 30px;
            background-color: rgb(255,255,255,0.72);
            /* border: 1px solid gray; */
            /* border: unset; */
            outline: none;
        }

    div.style_Container__FAdkl div.style_CouponBtns__ETQ29 button:hover{
            cursor: pointer;
        }

    div.style_Container__FAdkl div.style_TimeBar__37EwZ {
        position: relative;
    }

    div.style_Container__FAdkl div.style_TimeBar__37EwZ span{
            position: absolute;
            left:50px;
            font-size: 10px;
            color: rgb(0, 0, 0, 0.4);
            font-weight: 600;
        }

div.style_ModalContainer__8EiMk{
    margin-bottom: 10px;
}
    div.style_ModalContainer__8EiMk div.style_CloseBtn__wA7Ac{
        text-align: left;
        color: #808080;
        font-weight: 700;
        font-size:23px;
    }
    div.style_ModalContainer__8EiMk div.style_CloseBtn__wA7Ac span:hover{
            cursor: pointer;
        }
    div.style_ModalContainer__8EiMk div.style_Body__2q-jN{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* gap: 10px; */
    }
    /* margin-top: 15px; */
    div.style_ModalContainer__8EiMk div.style_Body__2q-jN div.style_ModalTitle__VFNqH{
            color: #808080;
            font-size: 25px;
            font-weight: 400;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
        }

div.style_Container__O7xd7{
    text-align: center;
    margin: 0;
    padding: 0;
}

    div.style_Container__O7xd7 div.style_CancleXBtn__8Jayx{
        text-align: left;
    }

    div.style_Container__O7xd7 div.style_CancleXBtn__8Jayx span{
            font-size: 22px;
        }

    div.style_Container__O7xd7 div.style_CancleXBtn__8Jayx span:hover{
            cursor: pointer;
        }

    div.style_Container__O7xd7 div.style_TextContnet__mQW2V div.style_Title__22N4- span{
                font-weight: 550;
                font-size: 23px;
            }

    div.style_Container__O7xd7 div.style_TextContnet__mQW2V div.style_TowRowText__2X7IK{
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            color: #808080;
            font-size: 19px;
            font-weight: 500;
        }

    div.style_Container__O7xd7 div.style_ContinueBtn__1UgIz{
        display: flex;
        justify-content: center;
        margin-top: 35px;
    }

    div.style_Container__O7xd7 div.style_ContinueBtn__1UgIz span{
            border: 1px solid #808080;
            border-radius: 20px;
            width: 55%;
        }

    div.style_Container__O7xd7 div.style_ContinueBtn__1UgIz span:hover{
            cursor: pointer;
        }
div.style_Container__akcGf{
    align-self: flex-start;
    flex: 1 1;
    justify-self: stretch;
    height: 100%;
}
    /* justify-content: center; */
    div.style_Container__akcGf div.style_Cols_3__jDcpu {
        display: flex;
        flex-flow: row-reverse wrap;
        min-width: 90%;
        text-align: center;
        justify-content: space-between;
        height: 100%;
    }
    div.style_Container__akcGf div.style_Cols_2__1F1Jm {
        display: flex;
        flex-flow: row-reverse wrap;
        min-width: 90%;
        text-align: center;
        justify-content: flex-end;
        height: 100%;
    }
    div.style_Container__akcGf div.style_FullScreen__1dZxT {
        display: flex;
        flex-flow: row-reverse wrap;
        min-width: 90%;
        text-align: center;
        justify-content: flex-end;
        height: 100%;
    }
.style_container__2MvXC {
  /* border: 1px solid red; */
  display: flex;
  flex-flow: column nowrap;
  /* justify-content: center; */
  align-items: center;
  /* height: 100vh; */
  overflow: auto;
  margin-bottom: 2rem;
  max-height: 45rem;
}
.style_Header__1FEyG {
  /* border: 1px solid red; */
  text-align: center;
  color: var(--mainWhite);
  font-size: 2em;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.style_Header__1FEyG div{
    flex: 1 1;
  }

@media screen and (max-width: 600px) {
    div.style_container__2MvXC {
      /* width: 90vw; */
      width: 100%;
      display: flex;
      flex-flow: column nowrap;

    }
  }
  
.style_container__2ghpd{
  /* border: 4px solid blue; */
  overflow: hidden;
  width: 100%;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  /* height: 100vh; */
  /* display: flex; */
  /* max-height: 60%; */
}
    /* max-height: 100%; */
    .style_container__2ghpd div.style_Spinner__2sfcK{
      /* border: 1px solid red; */
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60vh;
    }
.style_TopDiv__1MST2 {
  /* border: 8px solid purple; */
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 5px;
  overflow: hidden;
  /* height: 100%; */
}
.style_HistList__3YY9U {
  /* border: 5px solid pink; */
  z-index: 0;
  /* margin-left: 10px; */
  height: 40rem;
  overflow: auto;
  flex: 4 4;
  flex-flow: column nowrap;

}
.style_contBasket__rKRCo {
  /* border: 6px solid orange; */
  flex: 2 2;
  /* width: 100%; */
  height: 100%;
  margin-right: 10px;
}

.style_mobileContBasket__3_uqW{
  /* border: 6px solid blue; */
  flex: unset;
  width: 100%;
  margin-right: 0;
}

.style_Basket__2f0uy {
  /* border: 3px solid green; */
  display: flex;
  height: 40rem;
  flex-flow: column nowrap;
  background-color: var(--mainWhite);
  border-radius: var(--brdRadius);
  /* overflow: auto; */

  position: relative;
}
.style_closeBasket__2ryJr {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  position: absolute;
  top:0;
  left:0;
  padding-left: 10px;
  padding-top: 5px;
  cursor: pointer;
  font-size: 25px;
}

/* @media screen and (max-width: 600px) {
    .TopDiv {
      display: flex;
      flex-flow: column nowrap;
    }
  } */
.style_container__1IALK{
  /* border: 6px solid red; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-self: center; */
  /* height: 100%; */
  height: 100%;
  width: 100%;
}
.style_TopDiv__B8OUR {
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 100%;
    margin-top: 15px;
    padding-bottom: 10px;
}

.style_OrderNumber__1_IkO{
  font-size: calc(.2vw + 14px);
  font-weight: 600; 
}

.style_List__bEO0G {
  /* border: 6px solid red; */
  overflow: auto;
  height: 100%;
  margin-top: 35px;
  padding: 0 15px 0 15px;
  flex: 4 1;
}

.style_ButtonWrapper__3-Jot{
  /* border: 6px solid blue; */
  height: 15%;
  display: flex;
  justify-content: center;
}
.style_button__bgAaB {
  cursor: pointer;
  align-self: center;
  width: 50%;
  /* margin: 30px 0 30px 0; */
  border-radius: 20px;
  font-size: calc(.2vw + 12px);

}

/* @media screen and (max-width: 600px) {
    .TopDiv {
      display: flex;
      flex-flow: column nowrap;
    }
  } */

div.style_Container__27q9Q{
    display: flex;
    width: 100%;
    white-space: nowrap;
}

    /* border: 3px solid red; */

    div.style_Container__27q9Q div.style_RightDiv__32DW5 div.style_Image__2ZbqG{
            cursor: pointer;
            width: 100%;
            height: 100%;
        }

    div.style_Container__27q9Q div.style_RightDiv__32DW5 div.style_Image__2ZbqG img{
                max-width: calc(.2vw + 200px);
                max-height: calc(.2vw + 65px);
            }

    div.style_Container__27q9Q div.style_CenterDiv__2Q0BR{
        /* border: 3px solid blue; */
        width: 100%;
        cursor: default;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
    }

    div.style_Container__27q9Q div.style_CenterDiv__2Q0BR div.style_Name__2ecOg{
            font-size: calc(.2vw + 11px);
            font-weight: 600;
        }

    div.style_Container__27q9Q div.style_CenterDiv__2Q0BR div.style_CenterBottomDiv__1oogW{
            font-size: calc(.2vw + 10px);
        }

    div.style_Container__27q9Q div.style_CenterDiv__2Q0BR div.style_CenterBottomDiv__1oogW div.style_ItemCost__nxuMr{
                font-weight: 600;
            }

    div.style_Container__27q9Q div.style_CenterDiv__2Q0BR div.style_CenterBottomDiv__1oogW div.style_Comment__2MSvl{
                overflow: hidden;
                text-overflow: ellipsis;
                text-decoration: underline;
                color: #999;
            }

    div.style_Container__27q9Q div.style_CenterDiv__2Q0BR > * + *{
        margin-top: 15px;
    }

    div.style_Container__27q9Q div.style_LeftDiv__3NYXf{
        /* border: 3px solid orange; */
        cursor: default;
        text-align: right;
        width: 40%;
    }

    div.style_Container__27q9Q div.style_LeftDiv__3NYXf div.style_Amount__1FiYC{
            color: #888;
            font-size: calc(.2vw + 10px);

        }

    div.style_Container__27q9Q div.style_LeftDiv__3NYXf div.style_TotalCost__1HukP{
            font-size: calc(.2vw + 13px);
            color: #000;
            font-weight: 600;
        }


div.style_Container__27q9Q > * + *{
    margin-right: 15px;
}
.style_container__2Be9r{
  cursor: pointer;
  align-self: center;
  width: 100%;
  /* height: 150px; */
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin: 0 0 10px 10px;
  padding: 10px 0 10px 0;
  border-radius: var(--brdRadius);
  background-color: var(--mainWhite);
}
.style_TopDiv__2k6f0 {
    text-align: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: stretch;
    margin: 10px;
    min-height: 90px;
}
.style_info__o9KZu {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  flex: 5 1;
  margin-right: 5px;
}
.style_infoRow__mu5Om {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  /* justify-content: space-between; */
}
.style_col__1F6aS {
  /* border: 2px solid red; */
  min-width: 170px;
}
.style_col_del__1FIi0 {
  /* border: 2px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 170px;
}

.style_timerWrapper__3gx8m{
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 5px;

  position: relative;
}

.style_timerWrapper__3gx8m .style_inner__BnTYb{
    position: absolute;
    /* border: 2px solid red; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-size: 60px;
    border-radius: 100%;
  }

.style_timerWrapper__3gx8m .style_inner__BnTYb .style_time__1V7zK{
      font-size: 20px; 
      font-weight: 600;
      line-height: 20px;
    }

.style_timerWrapper__3gx8m .style_inner__BnTYb .style_unit__1Hsgc{
      font-size: 8px;
      font-weight: 600;
    }


.style_col_del__1FIi0 > * + * {
  margin-right: 10%;
}

.style_colDesc__1jAwX {
  /* border: 2px solid green; */
  font-size: 1.2em;
  font-weight: 600;


}
.style_colVal__ZPIMy {
  font-size: 1.2em;
  color: var(--mainColor);
}
.style_image__27a20 {
  flex: 1 1;
  align-self: center;
}
.style_line__1xlPw {
  width: 100%;
}
/* @media screen and (max-width: 600px) {
    .TopDiv {
      display: flex;
      flex-flow: column nowrap;
    }
  } */
@media (max-width: 600px) {
      /* display: flex;
      flex-flow: column nowrap; */
        /* border: 3px solid blue; */
        div.style_container__2Be9r .style_TopDiv__2k6f0 div.style_info__o9KZu div.style_infoRow__mu5Om{
          /* border: 1px solid red; */
          display: flex;
          justify-content: space-between;
          flex-flow: unset;
        }

          div.style_container__2Be9r .style_TopDiv__2k6f0 div.style_info__o9KZu div.style_infoRow__mu5Om div.style_col__1F6aS{
            /* border: 2px solid green; */
            min-width: unset;
            margin: auto;
          }
            
            div.style_container__2Be9r .style_TopDiv__2k6f0 div.style_info__o9KZu div.style_infoRow__mu5Om div.style_col__1F6aS div.style_colDesc__1jAwX{
              font-size: .9em;
              font-weight: 600;
            }
            div.style_container__2Be9r .style_TopDiv__2k6f0 div.style_info__o9KZu div.style_infoRow__mu5Om div.style_col__1F6aS div.style_colVal__ZPIMy{
              font-size: .8em;
            }

          div.style_container__2Be9r .style_TopDiv__2k6f0 div.style_info__o9KZu div.style_infoRow__mu5Om div.style_col_del__1FIi0{
            /* border: 3px solid pink; */
            margin: 0;
            width: 0;
          }
}

.style_container__DOZ0J{
  align-self: center;
  margin: auto;
  margin-top: 5%;
  /* width: 100vw; */
}
.style_TopDiv__1Sb8U {
    text-align: center;
    /* display: flex; */
    /* flex-flow: column nowrap; */
    /* justify-content: space-around; */
    margin: 5px;
}
.style_Desc__1JiyG {
  color: var(--mainWhite);
  opacity: .9;
  font-size: 1.5em;
  margin-bottom: 20px;
  /* display: flex; */
  /* flex-flow: column nowrap; */
}
.style_btn__2l2DD{
  opacity: .95;
  border-radius: 15px;
  padding: 10px 0 10px 0;
  background-color: transparent;
  cursor: pointer;
}
.style_btnDesc__2JqVv{
  padding: 5px 15px 5px 15px;
  color: var(--mainWhite);
  font-size: 1.3em;
  margin-bottom: 10px;
}
.style_imgBasket__1XZ6n {
  width: 80px;
}

@media screen and (max-width: 600px) {
    .style_TopDiv__1Sb8U {
      display: flex;
      flex-flow: column-reverse wrap;
    }
  }
  
div.style_container__1W2j_ {
  align-self: flex-start;
  flex: 1 1;
  display: flex;
  /* flex-flow: column; */
  justify-self: stretch;
  height: 70vh;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

  div.style_container__1W2j_ div.style_Header__2wtX7 {
    font-size: 60px;
    font-weight: 800;
    color: var(--mainWhite);
    padding-top: 30px;
    padding-bottom: 20px;
  }

  div.style_container__1W2j_ div.style_About__1E7sx {
    max-width: 1000px;
    background-color: var(--mainWhite);
    white-space: pre-line;
    text-align: center;
    padding: 30px;
    border-radius: var(--brdRadius);
    font-size: 20px;
    overflow: auto;
  }

@media (max-width: 850px){
    div.style_container__1W2j_ div.style_Header__2wtX7 {
      font-size: 40px;
      font-weight: 600;
      padding-top: 0px;
    }

    div.style_container__1W2j_ div.style_About__1E7sx{
      width: 100vw;
      font-size: 17px;
      word-wrap: break-word;
    }
}

/* @media screen and (max-width: 600px) {
  div.About {
    width: 90vw;
    display: flex;
    flex-flow: column-reverse wrap;
  }
} */
div.style_container__kmw_c {
  align-self: flex-start;
  flex: 1 1;
  display: flex;
  /* flex-flow: column; */
  justify-self: stretch;
  height: 100%;
  width: 80vw;
  margin: auto;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

  div.style_container__kmw_c div.style_Header__Qn_w2 {
    text-align: center;
    font-size: 36px;
    font-weight: 800;
    color: var(--mainWhite);
    padding-top: 30px;
    padding-bottom: 30px;
  }

  div.style_container__kmw_c div.style_Form__3s2mI {
    /* border: 1px solid red; */
    width: 500px;
    padding: 10px;
    overflow: auto;
    z-index: 1;
  }

  div.style_container__kmw_c div.style_Form__3s2mI div.style_FormLine__2tf23 {
      width: 100%;
    }

  div.style_container__kmw_c div.style_Form__3s2mI div.style_FormLine__2tf23 div.style_FormLineOne__1JznW {
        width: 100%;
        padding: 3px;
      }

  div.style_container__kmw_c div.style_Form__3s2mI div.style_FormLine__2tf23 div.style_FormLineTwo__1Ffum {
        width: 50%;
        padding: 3px;
        display: inline-block;
      }

  div.style_container__kmw_c div.style_Form__3s2mI div.style_FormLine__2tf23 .style_FormInput__2mue7 {
        width: 100%;
        line-height: 30px;
        padding: 5px;
        padding-left: 15px;
        padding-right: 15px;
        outline: unset;
        border-radius: 10px;
        font-size: 24px;
        font-family: inherit;
        font-weight: 300;
      }

  div.style_container__kmw_c div.style_Form__3s2mI div.style_FormLine__2tf23 .style_FormInput__2mue7.style_FormInputWrong__2rOmL {
          border: 2px solid #eb0028;
        }

  div.style_container__kmw_c div.style_RegisterButton__3oVYG {
    /* border: 1px solid rgb(0, 0, 0, 0.3); */
    font-size: 30px;
    width: 570px;
    margin-top: 20px;
    text-align: center;
    padding: 8px;
    color: var(--mainWhite);
    border-radius: 50px;
    cursor: pointer;

  }

  div.style_container__kmw_c div.style_RegisterButton__3oVYG:hover{
    opacity: .9;
  }

  div.style_container__kmw_c div.style_ModalContainer__mI6RG{
    display: flex;
    justify-content: center;
    align-items: center;
  }

@media (max-width: 850px){
    div.style_container__kmw_c div.style_Header__Qn_w2 {
      font-size: 26px;
      font-weight: 400;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    div.style_container__kmw_c div.style_Form__3s2mI {
      width: 120%;
      z-index: 0;
    }

      div.style_container__kmw_c div.style_Form__3s2mI div.style_FormLine__2tf23 {
        width: 100%;
      }
  
        div.style_container__kmw_c div.style_Form__3s2mI div.style_FormLine__2tf23 div.style_FormLineOne__1JznW {
        }
  
        div.style_container__kmw_c div.style_Form__3s2mI div.style_FormLine__2tf23 div.style_FormLineTwo__1Ffum {
        }

        div.style_container__kmw_c div.style_Form__3s2mI div.style_FormLine__2tf23 input.style_FormInput__2mue7 {
          font-size: 20px;
          padding: 5px;
          padding-left: 10px;
          padding-right: 10px;
        }
    div.style_container__kmw_c div.style_RegisterButton__3oVYG {
      z-index: 0;
      font-size: 30px;
      width: 80vw;
      margin-top: 40px;
      padding: 4px;
    }
}
div.style_ModalContainer__3AQ-I form{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
        /* gap: 30px; */
        div.style_ModalContainer__3AQ-I form div.style_ModalTitle__1urpn{
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 30px;
        }
        div.style_ModalContainer__3AQ-I form div.style_ModalTitle__1urpn div.style_WrongCodeText__3Gp7V{
                color: #FF0000;
                font-size: 15px;
                font-weight: 400;
            }
        div.style_ModalContainer__3AQ-I form div.style_ModalInputs__TPOHW{
            margin-bottom: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        /* gap: 0.6vw; */
        div.style_ModalContainer__3AQ-I form div.style_ModalInputs__TPOHW input{
                margin: 7px;
            }
        div.style_ModalContainer__3AQ-I form div.style_ModalInputs__TPOHW div.style_NewCodeText__30LZL{
                color: #FF0000;
                font-size: 17px;
            }
        div.style_ModalContainer__3AQ-I form div.style_ModalInputs__TPOHW div.style_NewCodeText__30LZL span:nth-child(1){
                    font-weight: 300;
                }
        div.style_ModalContainer__3AQ-I form div.style_ModalInputs__TPOHW div.style_NewCodeText__30LZL span:nth-child(2){
                    font-weight: 600;
                    text-decoration: underline;
                }
        div.style_ModalContainer__3AQ-I form div.style_ModalInputs__TPOHW div.style_NewCodeText__30LZL span:nth-child(1):hover{
                    cursor: context-menu;
                }
        div.style_ModalContainer__3AQ-I form div.style_ModalInputs__TPOHW div.style_NewCodeText__30LZL span:nth-child(2):hover{
                    cursor: pointer;
                    color: #ff5c5c;
                }
        div.style_ModalContainer__3AQ-I form div.style_ModalBtns__2kkQU{
            display: flex;
            /* justify-content: space-around; */
            align-items: center;
            margin-top: 20px;
    
            /* button:nth-child(2):hover{
                background-color: #ff2323;
                color: #FFFF;
                cursor: pointer;
            }
    
            button:nth-child(1):hover{
                background-color: #cacaca;
                color: #ffff;
                cursor: pointer;
            } */
        }
        /* gap: 7vw; */
        div.style_ModalContainer__3AQ-I form div.style_ModalBtns__2kkQU button{
                margin: 0 15px 0 15px;
                font-family: Heebo;
                outline: gray;
                border: none;
                border-radius: 25px;
                width: 120px;
                height: 50px;
                font-size: 20px;
                /* color: #ff0000; */
                background-color: #FFFF;
                cursor: pointer;
                
            }
        div.style_ModalContainer__3AQ-I form div.style_ModalBtns__2kkQU button:nth-child(2){
                border: 1px solid #000;
                /* color: #ff0000; */
            }
        div.style_ModalContainer__3AQ-I form div.style_ModalBtns__2kkQU button:nth-child(1){
                border: 1px solid #cacaca;
                color: #ababab;
            }
div.style_Inputs__2rVgM{
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 0.6vw; */
    direction: ltr;
}
    
    div.style_Inputs__2rVgM input{
        text-align: center;
        /* width: 3.5vw; */
        /* margin-right: 0.6vw; */
        outline: none;
        border: none;
        border-bottom: 2px solid #cacaca;
        font-size: 27px;
        font-weight: 500;
        background-color: unset;
        /* color: rgb(0,0,0,0.68); */
    }

div.style_ModalContainer__3b7_P{
    width: 100%;
}

    div.style_ModalContainer__3b7_P div.style_CloseBtn__3GdwY{
        text-align: right;
        color: #808080;
        font-weight: 700;
        font-size:23px;
    }

    div.style_ModalContainer__3b7_P div.style_CloseBtn__3GdwY span:hover{
            cursor: pointer;
        }

    div.style_ModalContainer__3b7_P div.style_Content__3mpjT{
        height: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: #FFF;
        padding: .2rem;
    }

    div.style_ModalContainer__3b7_P div.style_Content__3mpjT div.style_BodyContent__3sn8K{
            text-align: center;
            color: #000;
            font-size: 24px;
            white-space: nowrap;
        }

    div.style_ModalContainer__3b7_P div.style_Content__3mpjT div.style_BodyContent__3sn8K :nth-child(3){
                font-weight: 600;
            }

    div.style_ModalContainer__3b7_P div.style_Content__3mpjT div.style_BodyContent__3sn8K :nth-child(4) span{
                    font-weight: 600;
                    text-decoration: underline;
                    cursor: pointer;
                }

    div.style_ModalContainer__3b7_P div.style_Content__3mpjT div.style_BodyContent__3sn8K :nth-child(4) span:hover{
                    opacity: .6;
                }

    div.style_ModalContainer__3b7_P div.style_Content__3mpjT div.style_FootContent__S4jdO{
            text-align: center;
            font-size: 15px;
            white-space: nowrap;
        }

    div.style_ModalContainer__3b7_P div.style_Content__3mpjT div.style_FootContent__S4jdO :nth-child(1){
                text-decoration: underline;
            }

    div.style_ModalContainer__3b7_P div.style_Content__3mpjT p{
            margin: 0;
        }

.style_container__1927f{
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  height: 70vh;
}

  .style_container__1927f div.style_Header__1uLJg {
    font-size: 60px;
    font-weight: 800;
    color: var(--mainWhite);
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .style_container__1927f div.style_Contacts__1UaC7 {
    width: 100vw;
    max-width: 800px;
    background-color: var(--mainWhite);
    padding: 20px 150px 20px 150px;
    border-radius: var(--brdRadius);
    font-size: 20px;
    overflow: auto;
  }

  .style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsName__2tWME {
      font-size: 30px;
    }

  .style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsPhone__WfnJR {
      font-size: 30px;
    }

  .style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsPhone__WfnJR a {
        color: inherit;
        text-decoration: inherit;
      }

  .style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsDesc__3icjk {
      font-size: 30px;
    }

  .style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsEmail__3oK3o {
      font-size: 30px;
    }

  .style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsEmail__3oK3o a {
        color: inherit;
        text-decoration: inherit;
      }

.style_Contacts__1UaC7> * {
  margin: 3px;
  color: var(--black);
  text-align: center;
}


@media (max-width: 850px){
    div.style_container__1927f div.style_Header__1uLJg {
      font-size: 40px;
      font-weight: 600;
      padding-top: 0px;
    }

    div.style_container__1927f div.style_Contacts__1UaC7 {
      width: 100vw;
      background-color: var(--mainWhite);
      /* text-align:justify; */
      /* align-content: center; */
      padding: 10px 25px 10px 25px;
      font-size: 14px;
  
      overflow: auto;
    }
  
      div.style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsName__2tWME {
        font-size: 20px;
        font-weight: 500;
      }
  
      div.style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsPhone__WfnJR {
        font-size: 18px;
      }
  
        div.style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsPhone__WfnJR a {
          color: inherit;
          text-decoration: inherit;
        }
  
      div.style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsDesc__3icjk {
        font-size: 20px;
      }
  
      div.style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsEmail__3oK3o {
        font-size: 25px;
      }
  
        div.style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsEmail__3oK3o a {
          color: inherit;
          text-decoration: inherit;
        }

}

/* @media screen and (max-width: 600px) {
  div.Contacts {
    width: 90vw;
    display: flex;
    flex-flow: column-reverse wrap;
  }
} */
.style_container__2mg-e{
  padding: 0;
  margin: 0;

  align-self: center;
}

  .style_container__2mg-e .style_TopDiv__gXspK {
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin: 15px;
  }

  .style_container__2mg-e .style_TopDiv__gXspK .style_day__24X6r {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      font-size: 24px;
    }

  .style_container__2mg-e .style_TopDiv__gXspK .style_day__24X6r .style_dayName__1eh0_ {
        justify-self: flex-start;
      }

  .style_container__2mg-e .style_TopDiv__gXspK .style_day__24X6r .style_dayHours__xdYWI {
        justify-self: flex-end;
      }


@media (max-width: 850px){
      .style_container__2mg-e .style_TopDiv__gXspK .style_day__24X6r {
        font-size: 18px;
      }
}
/* @media screen and (max-width: 600px) {
    .TopDiv {
      display: flex;
      flex-flow: column-reverse wrap;
    }
  } */

div.style_Container__2xsja {
    /* border: 1px solid red; */
    height: 100%;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    margin-bottom: 5rem;
}

    div.style_Container__2xsja div.style_PriceInfo__1lkgm{
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      border-radius: 10px;
      padding: 15px;
      text-align: center;
      width: 50%;
    }

    div.style_Container__2xsja div.style_PriceInfo__1lkgm div.style_PriceTitle__3u5qq{
        font-size: 28px;
        font-weight: 500;
      }

    div.style_Container__2xsja div.style_PriceInfo__1lkgm div.style_PriceDescription__36UdW{
        font-size: 15px;
      }
  
  /* @media screen and (max-width: 600px) {
      div.PPolicy {
        width: 90vw;
        display: flex;
        flex-flow: column-reverse wrap;
      }
  } */
  
  @media (max-width: 850px){
    div.style_Container__2xsja {
      margin-bottom: 0rem;
    }

      div.style_Container__2xsja div.style_PriceInfo__1lkgm{
        width: 70%;
      }
        div.style_Container__2xsja div.style_PriceInfo__1lkgm div.style_PriceTitle__3u5qq{
          font-size: 20px;
          font-weight: 500;
        }
  
        div.style_Container__2xsja div.style_PriceInfo__1lkgm div.style_PriceDescription__36UdW{
          font-size: 10px;
        }
  }
div.style_Container__BzmGx{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 70vh;
}
    /* min-height: 100%; */
    div.style_Container__BzmGx div.style_Title__1yCkJ{
        margin: 15px;
        color: #FFFF;
        font-size: 25px;
    }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe{
        /* border: 1px solid red; */
        height: 100%;
        width: 100%;
    }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe div.style_OptionBtns__3J51v{
            display: flex;
            justify-content: center;
        }
    /* margin-right: 15px; */
    div.style_Container__BzmGx div.style_UserChoosing__20mCe div.style_OptionBtns__3J51v button{
                /* margin-left: 15px; */
                width: 100%;
                height: 30px;
                font-size: 15px;
                outline: none;
                color: #FFFF;
                border: none;
                border-radius: 3px;
                cursor: pointer;
                font-family: 'Heebo', sans-serif;
            }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe div.style_OptionBtns__3J51v button:hover{
                opacity: .9;
            }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe div.style_OptionBtns__3J51v > * + *{
            margin-right: 3%;
        }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe form{
            /* border: 4px solid green; */
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            height: 90%;
            justify-content: space-between;
            width: 100%;
        }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe form input, div.style_Container__BzmGx div.style_UserChoosing__20mCe form select, div.style_Container__BzmGx div.style_UserChoosing__20mCe form textarea{
                display: inline-block;
                width: 100%;
                height: 30px;
                border:none;
                outline: none;
                border-radius: 3px;
                resize: none;
                overflow: auto;
                margin-bottom: 6px;
                font-family: 'Heebo', sans-serif;
                padding: 2px 5px 2px 5px;
            }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe form textarea{
                height: 80px;
                margin-bottom: 6px;
            }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe form input::-webkit-input-placeholder, div.style_Container__BzmGx div.style_UserChoosing__20mCe form textarea::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #adabab;
                opacity: 1; /* Firefox */
              }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe form input:-ms-input-placeholder, div.style_Container__BzmGx div.style_UserChoosing__20mCe form textarea:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #adabab;
                opacity: 1; /* Firefox */
              }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe form input::placeholder, div.style_Container__BzmGx div.style_UserChoosing__20mCe form textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #adabab;
                opacity: 1; /* Firefox */
              }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe form textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #adabab;
              }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe form input:-ms-input-placeholder, div.style_Container__BzmGx div.style_UserChoosing__20mCe form textarea::placeholder { /* Internet Explorer 10-11 */
                color: #adabab;
              }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe form textarea:-ms-input-placeholder { /* Microsoft Edge */
                color: #adabab;
              }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe form input::-ms-input-placeholder, div.style_Container__BzmGx div.style_UserChoosing__20mCe form textarea::placeholder { /* Microsoft Edge */
                color: #adabab;
              }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe form .style_Commands__eTRx7{
                display: flex;
                flex-direction: column;
            }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe form .style_Btns__23us3{
                display: flex;
                flex-direction: column;
            }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe form .style_Btns__23us3 button{
                    margin-top: 15px;
                    height: 50px;
                    border-radius: 30px;
                    outline: none;
                    border:none;
                    color: #FFFF;
                    font-size: 27px;
                    font-family: 'Heebo', sans-serif;
                    cursor: pointer;
                }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe form .style_Btns__23us3 button:nth-child(2){
                    background-color: unset;
                    border-radius: 30px;
                    outline: none;
                    border: 2px solid rgb(255, 255, 255, 0.3);
                    color: #FFFF;
                }
    div.style_Container__BzmGx div.style_UserChoosing__20mCe form .style_Btns__23us3 button:hover{
                    opacity: .9;
                }


  
@media (max-width: 850px){
        div.style_Container__BzmGx div.style_Title__1yCkJ{
            font-size: 15px;
        }
            div.style_Container__BzmGx div.style_UserChoosing__20mCe form{
                display: flex;
                flex-direction: column;
                margin-top: 10px;
                align-items: center;
            }

                div.style_Container__BzmGx div.style_UserChoosing__20mCe form input, div.style_Container__BzmGx div.style_UserChoosing__20mCe form select, div.style_Container__BzmGx div.style_UserChoosing__20mCe form textarea{
                    width: 260px;
                }
                    /* gap: 10px; */
        
                    div.style_Container__BzmGx div.style_UserChoosing__20mCe form .style_Btns__23us3 button{
                        margin-top: 10px;
                        width: 250px;
                        height: 30px;
                        font-size: 19px;
                    }
                    
                    div.style_Container__BzmGx div.style_UserChoosing__20mCe form .style_Btns__23us3 button:nth-child(2){
                        width: 250px;
                        height: 30px;
                    }
}
div.style_Container__36J1M{
    text-align: center;
    margin: 0;
    padding: 0;
    position: relative;
}

    div.style_Container__36J1M div.style_CancleXBtn__386wC{
        position: absolute;
        left: 0;
        text-align: left;
        color: #808080;
    }

    div.style_Container__36J1M div.style_CancleXBtn__386wC span{
            font-size: 22px;
        }

    div.style_Container__36J1M div.style_CancleXBtn__386wC span:hover{
            cursor: pointer;
        }

    div.style_Container__36J1M div.style_TextContnet__2DsZU{
        margin-top: 5px;
    }

    div.style_Container__36J1M div.style_TextContnet__2DsZU div.style_SadImg__31azz{

        }

    div.style_Container__36J1M div.style_TextContnet__2DsZU div.style_Title__j-rQq span{
                font-weight: 550;
                font-size: 23px;
            }

    div.style_Container__36J1M div.style_TextContnet__2DsZU div.style_ThreeRowText__2JW6W{
            margin-top: 5px;
            display: flex;
            flex-direction: column;
            color: #808080;
            font-size: 18px;
            font-weight: 200;
        }

    div.style_Container__36J1M div.style_ContinueBtn__3Z5X-{
        margin-top: 5px;
        display: flex;
        justify-content: center;
    }

    div.style_Container__36J1M div.style_ContinueBtn__3Z5X- p:nth-child(1){
            border: 1px solid #00000029;
            border-radius: 20px;
            width: -webkit-max-content;
            width: -moz-max-content;
            width: max-content;
            padding: 5px;
            padding-left: 20px;
            padding-right: 20px;
        }

    div.style_Container__36J1M div.style_ContinueBtn__3Z5X- p:hover{
            cursor: pointer;
        }
div.style_ModalContainer__MfoaB{
    padding: 5px;
    width: 100%;
}

    div.style_ModalContainer__MfoaB div.style_CloseBtn__1akbW{
        text-align: right;
        color: #808080;
        font-weight: 700;
        font-size:23px;
    }

    div.style_ModalContainer__MfoaB div.style_CloseBtn__1akbW span:hover{
            cursor: pointer;
        }

    div.style_ModalContainer__MfoaB div.style_Body__3kMsz{
        height: 220px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    div.style_ModalContainer__MfoaB div.style_Body__3kMsz div.style_BodyContent__1p2w1{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            color: #000;
            font-size: 17px;
        }

    div.style_ModalContainer__MfoaB div.style_Body__3kMsz div.style_BodyContent__1p2w1 :nth-child(1){
                font-size: 22px;
                color: #000;
            }

    div.style_ModalContainer__MfoaB div.style_Body__3kMsz div.style_BikeImage__1_r_K{
            margin: auto;
            margin-top: 10px;
            margin-bottom: 10px;
            width: 28%;
        }

    div.style_ModalContainer__MfoaB div.style_Body__3kMsz div.style_BodyButtons__2kgwD{
            display: flex;
            align-items: center;
            text-align: center;
            font-size: 12px;
        }

    div.style_ModalContainer__MfoaB div.style_Body__3kMsz div.style_BodyButtons__2kgwD button{
                font-family: 'Heebo', sans-serif;
                border: 1px solid #000;
                cursor: pointer;
                border-radius: 30px;
                width: 120px;
                height: 40px;
                font-size: 16px;
                background-color: unset;
            }

    div.style_ModalContainer__MfoaB div.style_Body__3kMsz div.style_BodyButtons__2kgwD :nth-child(1){
                font-size: 20px;
            }

    div.style_ModalContainer__MfoaB div.style_Body__3kMsz div.style_BodyButtons__2kgwD button:hover{
                opacity: .9;
            }

    div.style_ModalContainer__MfoaB div.style_Body__3kMsz div.style_BodyButtons__2kgwD > * + *{
            margin-right: 20px;
        }

div.style_Container__k8bhc{
    /* border: 1px solid blue; */
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    position: relative;
}

    div.style_Container__k8bhc div.style_UserChoosing__1gezp{
        /* border: 1px solid blue; */
        height: 100%;

        /* div.InputFields form > *{
            margin-bottom: 5px;
        } */
    }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_OptionBtns__19kf0{
            /* border: 3px solid green; */
            display: flex;
            justify-content: center;
        }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_OptionBtns__19kf0 button{
                width: 100%;
                height: 30px;
                outline: none;
                color: #FFFF;
                border: none;
                border-radius: 3px;
                /* margin: 0 7px 0 7px; */
                cursor: pointer;
                font-family: 'Heebo', sans-serif;
                font-size: 15px;
            }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_OptionBtns__19kf0 button:hover{
                opacity: .9;
            }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_OptionBtns__19kf0 > * + *{
            margin-right: 3%;
        }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG{
            height: 100%
        }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form{
            /* border: 13px solid purple; */
            margin-top: 10px;
            height: 100%;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0{
                /* border: 3px solid blue; */
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 input{
                    display: inline-block;
                    width: 100%;
                    height: 30px;
                    border:none;
                    outline: none;
                    border-radius: 3px;
                    resize: none;
                    overflow: auto;
                    font-family: 'Heebo', sans-serif;
                    padding: 2px 5px 2px 5px;
                }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 input::-webkit-input-placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #adabab;
                    opacity: 1; /* Firefox */
                }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 input:-ms-input-placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #adabab;
                    opacity: 1; /* Firefox */
                }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 input::placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #adabab;
                    opacity: 1; /* Firefox */
                }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 input:-ms-input-placeholder{ /* Internet Explorer 10-11 */
                    color: #adabab;
                }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 input::-ms-input-placeholder{ /* Microsoft Edge */
                    color: #adabab;
                }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 div.style_StreetField__2v-JZ{
                    /* border: 1px solid red; */
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 div.style_StreetField__2v-JZ div.style_LeftElement__zRaHf{
                        position: absolute;
                        left: 10px;
                    }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 div.style_StreetField__2v-JZ div.style_LeftElement__zRaHf label{
                            font-size: 12px;
                            color: #adabab;
                            cursor: pointer;
                        }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 div.style_StreetField__2v-JZ div.style_LeftElement__zRaHf label:nth-child(1){
                            font-weight: 550;
                            text-decoration: underline;
                            color: #000;
                        }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 div.style_StreetField__2v-JZ input{
                        display: inline-block;
                        height: 30px;
                        border:none;
                        outline: none;
                        border-radius: 3px;
                        resize: none;
                        overflow: auto;
                        font-family: 'Heebo', sans-serif;
                        padding: 2px 5px 2px 5px;
                    }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 div.style_StreetField__2v-JZ input::-webkit-input-placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #adabab;
                        opacity: 1; /* Firefox */
                    }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 div.style_StreetField__2v-JZ input:-ms-input-placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #adabab;
                        opacity: 1; /* Firefox */
                    }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 div.style_StreetField__2v-JZ input::placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: #adabab;
                        opacity: 1; /* Firefox */
                    }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 div.style_StreetField__2v-JZ input:-ms-input-placeholder{ /* Internet Explorer 10-11 */
                        color: #adabab;
                    }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 div.style_StreetField__2v-JZ input::-ms-input-placeholder{ /* Microsoft Edge */
                        color: #adabab;
                    }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 div.style_TowInputInRow__1cQSc{
                    display: flex;
                    width: 100%;
                }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 div.style_TowInputInRow__1cQSc input{
                        height: 30px;
                    }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 div.style_TowInputInRow__1cQSc input:nth-child(1){
                        margin-left: 6px;
                    }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 div.style_CheckTerm__kynY9{
                    /* border: 1px solid red; */
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    overflow: hidden;
                }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 div.style_CheckTerm__kynY9 input{
                        width: 15px;
                    }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 div.style_CheckTerm__kynY9 span{
                        /* border: 1px solid red; */
                        width: 20rem;
                        font-size: 17px;
                        /* font-weight: 600; */
                        color: #FFF;
                    }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 div.style_CheckTerm__kynY9 .style_TextTermBtn__2a8J_{
                        font-weight: 900;
                        text-decoration: underline;
                        color: #fff;
                    }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 div.style_CheckTerm__kynY9 .style_TextTermBtn__2a8J_:hover{
                        cursor: pointer;
                    }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_Inputs__3Njp0 > *{
                margin-bottom: 5px;
            }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_BottomBtns__Uw0si{
                /* border: 1px solid red; */
                display: flex;
                flex-direction: column;

                /* button:nth-child(1){
                    background-color: #FF0000;
                    width: 350px;
                    border-radius: 30px;
                    outline: none;
                    border:none;
                    color: #FFFF;
                } */
            }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_BottomBtns__Uw0si button{
                    font-family: 'Heebo', sans-serif;
                    /* height: 50px; */
                    height: 35px;
                    /* font-size: 27px; */
                    font-size: 22px;
                    cursor: pointer;

                    width: 350px;
                    border-radius: 30px;
                    outline: none;
                    border:none;
                    color: #FFF;
                }

    /* button:nth-child(2){
                    margin-top: 15px;
                    background-color: unset;
                    width: 350px;
                    border-radius: 30px;
                    outline: none;
                    border: 2px solid rgb(255, 255, 255, 0.3);
                    color: #FFFF;
                } */

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_BottomBtns__Uw0si button:hover{
                    opacity: .9;
                }

    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_BottomBtns__Uw0si > * + *{
                /* margin-top: 15px; */
                margin-top: 7px;
            }

@media (max-width: 850px){
    div.style_Container__k8bhc {
        width: 300px;
        padding-top: 10px;
    }
            div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form{
                display: flex;
                flex-direction: column;
                /* gap: 5px; */
                margin-top: 10px;
                align-items: center;
            }

                div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form input{
                }
    
                div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_StreetField__2v-JZ{
                    position: relative;
                    display: flex;
                    align-items: center;
                }
    
                    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_StreetField__2v-JZ div.style_LeftElement__zRaHf{
                        position: absolute;
                        left: 10px;
                    }
                        
                        div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_StreetField__2v-JZ div.style_LeftElement__zRaHf label{
                            font-size: 12px;
                            color: #adabab;
                        }
                        div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_StreetField__2v-JZ div.style_LeftElement__zRaHf label:hover{
                            cursor: pointer;
                        }

                        div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_StreetField__2v-JZ div.style_LeftElement__zRaHf label:nth-child(1){
                            font-weight: 550;
                            text-decoration: underline;
                            color: #000000;
                        }

                    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_StreetField__2v-JZ input{
                    }
    
                div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_TowInputInRow__1cQSc{
                    width: 250px;
                }

                div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_CheckTerm__kynY9{
                    width: 250px;
                    color: #FFF;
                }
                    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_CheckTerm__kynY9 span{
                    }
                    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_CheckTerm__kynY9 input{
                        width: 10%;
                    }

                div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_BottomBtns__Uw0si{
                    /* button:nth-child(2){
                        margin-top: 10px;
                        width: 250px;
                        height: 30px;
                    } */
                }
                        /* gap: 10px;   */
                    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_BottomBtns__Uw0si button{
                        font-size: 19px;
                    }
    
                    div.style_Container__k8bhc div.style_UserChoosing__1gezp div.style_InputFields__1lXPG form div.style_BottomBtns__Uw0si button{
                        width: 250px;
                        height: 30px;
                    }
}
div.style_ModalContainer__DHgMb{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* gap: 30px; */
    margin-top: 15px;
}
    
    div.style_ModalContainer__DHgMb div.style_ModalTitle__y2J_E{
        color: #000000;
        font-size: 50px;
        font-weight: 600;
    }
    
    div.style_ModalContainer__DHgMb div.style_ModalContent__1Id-a{
        margin: 50px;
        margin-top: 0;
        font-weight: 500;
    }
    
    div.style_ModalContainer__DHgMb div.style_ModalButton__1j5h3{
        height: 40px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #000000;
        border-radius: 20px;
        margin-bottom: 20px;
        font-weight: 550;
        font-size: 20px;
    }
    
    div.style_ModalContainer__DHgMb div.style_ModalButton__1j5h3 span{
            margin: 10px;
        }
    
    div.style_ModalContainer__DHgMb div.style_ModalButton__1j5h3:hover{
        background-color: #000000;
        border: 1px solid #FFFFFF;
        color: #FFFFFF;
        cursor: pointer;
    }

div.style_ModalContainer__DHgMb > *{
    margin-bottom: 30px; 
}

div.style_TopContent__10ogp{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
    /* margin-top: 15px; */
    div.style_TopContent__10ogp div.style_Title__gsJOg{
        color: #FFFFFF;
        font-weight: 500;
        font-size: 23px;
        margin-bottom: 11px;
    }
    /* gap: 5px; */
    div.style_TopContent__10ogp div.style_Address__2Co0V{
        margin-top: 5px;
        margin-bottom: 11px;
        /* display: flex; */
        display: none;
        justify-content: center;
        /* align-items: center; */
        background-color: #FFFFFF;
        border: 1px solid black;
        border-radius: 6px;
        width: 320px;
        height: 26px;
        overflow: auto;
        text-overflow: ellipsis;
    }
    div.style_TopContent__10ogp div.style_Address__2Co0V span{
            color: #808080;
        }

div.style_BottomContent__3Inz4{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/* gap: 10px; */

div.style_BottomContent__3Inz4 div.style_AddressUpdateBtn__3lyBY{
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        width: 300px;
        height: 50px;
        cursor: pointer;
    }

div.style_BottomContent__3Inz4 div.style_AddressUpdateBtn__3lyBY span{
            color: #FFFFFF;
            font-family: 'Heebo', sans-serif;
            font-size: 25px;
        }

div.style_BottomContent__3Inz4 div.style_AddressUpdateBtn__3lyBY:hover{
        opacity: .9;
    }

div.style_BottomContent__3Inz4 div.style_BackBtn__23SdL{
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 25px;
        background-color: unset;
        border: 1px solid #FFFFFF;
        width: 300px;
        height: 50px;
        cursor: pointer;
    }

div.style_BottomContent__3Inz4 div.style_BackBtn__23SdL span{
            color: #FFFFFF;
            /* font-weight: 300; */
            font-family: 'Heebo', sans-serif;
            font-size: 25px;
            
        }

div.style_BottomContent__3Inz4 div.style_BackBtn__23SdL:hover{
        opacity: .9;
    }


@media (max-width: 850px){
        div.style_TopContent__10ogp div.style_Title__gsJOg{
            font-size: 15px;
            text-align: center;
        }
    
        div.style_TopContent__10ogp div.style_Address__2Co0V{
            width: 200px;
        }
            
            div.style_TopContent__10ogp div.style_Address__2Co0V span{
                margin-top: 3px;
                font-size: 13px;
            }

    div.style_BottomContent__3Inz4{
        margin-top: 20px;
    }
        
        div.style_BottomContent__3Inz4 div.style_AddressUpdateBtn__3lyBY{
            width: 170px;
            height: 30px;
        }
    
            div.style_BottomContent__3Inz4 div.style_AddressUpdateBtn__3lyBY span{
                font-size: 15px;
            }
    
        div.style_BottomContent__3Inz4 div.style_BackBtn__23SdL{
            width: 170px;
            height: 30px;
        }
    
            div.style_BottomContent__3Inz4 div.style_BackBtn__23SdL span{
                font-size: 15px;
            }
}
div.style_ModalContainer__31H-C{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 43vh;
    position: relative;
}
    
    div.style_ModalContainer__31H-C div.style_SpinnerIcon__1vKjX{
        position: absolute;
        left: 48%;
    }
    
    div.style_ModalContainer__31H-C div.style_Content__3xSGs{
        margin-top: 163px;
    }
    
    div.style_ModalContainer__31H-C div.style_Content__3xSGs span{
            font-weight: 500;
        }
div.style_Container__1empT{
    text-align: center;
    margin: 0;
    padding: 0;
}

    div.style_Container__1empT div.style_CancleXBtn__ly1MK{
        text-align: left;
    }

    div.style_Container__1empT div.style_CancleXBtn__ly1MK span{
            font-size: 22px;
        }

    div.style_Container__1empT div.style_CancleXBtn__ly1MK span:hover{
            cursor: pointer;
        }

    div.style_Container__1empT div.style_TextContnet__2-_K7 div.style_Title__2--dP span{
                font-weight: 550;
                font-size: 23px;
            }

    div.style_Container__1empT div.style_TextContnet__2-_K7 div.style_TowRowText__2EuKC{
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            color: #808080;
            font-size: 16px;
            font-weight: 500;
        }

    div.style_Container__1empT div.style_TextContnet__2-_K7 div.style_Price__2pglK span:nth-child(1){
                font-weight: 650;
                font-size: 20px;
            }

    div.style_Container__1empT div.style_TextContnet__2-_K7 div.style_Price__2pglK span:nth-child(2){
                font-weight: 650;
                font-size: 30px;
            }

    div.style_Container__1empT div.style_ContinueBtn__1e1U8{
        display: flex;
        justify-content: center;
        margin-top: 35px;
    }

    div.style_Container__1empT div.style_ContinueBtn__1e1U8 span{
            border: 1px solid #808080;
            border-radius: 20px;
            width: 55%;
        }

    div.style_Container__1empT div.style_ContinueBtn__1e1U8 span:hover{
            cursor: pointer;
        }
div.style_ModalContainer__1Th4s div.style_CloseBtn__2CZSm{
        text-align: left;
        color: #808080;
        font-weight: 700;
        font-size:23px;
    }
        
        div.style_ModalContainer__1Th4s div.style_CloseBtn__2CZSm span:hover{
            cursor: pointer;
        }
    div.style_ModalContainer__1Th4s div.style_Body__3bF-b{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* gap: 30px; */
    }
    /* margin-top: 15px; */
    div.style_ModalContainer__1Th4s div.style_Body__3bF-b div.style_ModalTitle__L21f2{
            margin-top: 30px;
            text-align: center;
            color: #000;
            font-size: 25px;
            font-weight: 500;
        }

div.style_Container__2Ji5b{
    text-align: center;
    margin: 0;
    padding: 0;
}

    div.style_Container__2Ji5b div.style_CancleXBtn__3rKSD{
        text-align: left;
    }

    div.style_Container__2Ji5b div.style_CancleXBtn__3rKSD span{
            font-size: 22px;
        }

    div.style_Container__2Ji5b div.style_CancleXBtn__3rKSD span:hover{
            cursor: pointer;
        }

    div.style_Container__2Ji5b div.style_TextContnet__14UHw div.style_Title__HSluZ span{
                font-weight: 550;
                font-size: 23px;
            }

    div.style_Container__2Ji5b div.style_TextContnet__14UHw div.style_TowRowText__2OrFF{
            margin-top: 5px;
            display: flex;
            flex-direction: column;
            color: #808080;
            font-size: 16px;
        }

    div.style_Container__2Ji5b div.style_TextContnet__14UHw div.style_Price__1bQOj span:nth-child(1){
                font-weight: 650;
                font-size: 20px;
            }

    div.style_Container__2Ji5b div.style_TextContnet__14UHw div.style_Price__1bQOj span:nth-child(2){
                font-weight: 650;
                font-size: 30px;
            }

    div.style_Container__2Ji5b div.style_ContinueBtn__2XmlP{
        display: flex;
        justify-content: center;
        margin-top: 10px;
        /* border: 1px solid #808080; */
    }

    /* width: 55%; */

    div.style_Container__2Ji5b div.style_ContinueBtn__2XmlP span{
            border: 1px solid #808080;
            border-radius: 20px;
            width: 55%;
        }

    /* border-radius: 20px; */

    div.style_Container__2Ji5b div.style_ContinueBtn__2XmlP span:hover{
            cursor: pointer;
        }
div.style_ModalContainer__3uz8-{
    width: 100%;
    height: 100%;
}
    div.style_ModalContainer__3uz8- form{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    div.style_ModalContainer__3uz8- form div.style_CloseBtn__3fUFz{
            /* border: 1px solid red; */
            margin-right: auto;
            color: #808080;
            font-weight: 800;
            font-size:33px;
            width: -webkit-max-content;
            width: -moz-max-content;
            width: max-content;
            cursor: pointer;

        }
    div.style_ModalContainer__3uz8- form div.style_CloseBtn__3fUFz:hover{
            opacity: .8;
        }
    div.style_ModalContainer__3uz8- form div.style_BodyContent__2aR2x{
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: .2rem;
        }
    div.style_ModalContainer__3uz8- form div.style_BodyContent__2aR2x label{
                font-size: 27px;
                font-weight: 500;
            }
    div.style_ModalContainer__3uz8- form div.style_BodyContent__2aR2x input{
                width: 400px;
                height: 50px;
                font-size: 30px;
                background-color: #e7e7e7;
                padding: .5rem;
                text-align: center;
                border-radius: 15px;
                border: unset;
            }
    div.style_ModalContainer__3uz8- div.style_SendButton__20qbs{
        /* border: 1px solid red; */
        width: 100%;
        text-align: center;
    }
    div.style_ModalContainer__3uz8- div.style_SendButton__20qbs button{
            font-size: 30px;
            border-radius: 25px;
            background-color: unset;
            cursor: pointer;
            outline: none;
            width: 40%;
        }
    div.style_ModalContainer__3uz8- div.style_SendButton__20qbs button:hover{
            opacity: .8;
        }


@media (max-width: 600px){
                div.style_ModalContainer__3uz8- form div.style_BodyContent__2aR2x label{
                    font-size: 25px;
                    font-weight: 500;
                }
                div.style_ModalContainer__3uz8- form div.style_BodyContent__2aR2x input{
                    width: 250px;
                    height: 40px;
                }
                div.style_ModalContainer__3uz8- form div.style_SendButton__20qbs button{
                    font-size: 25px;
                }
}

div.style_Container__1DUEy{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65vh;
    width: 35vw;
    position: relative;
}
    /* border: 3px solid blue; */
    div.style_Container__1DUEy div.style_CreditPayTitle__38Nsc{
        margin-top: 20px;
        width: max-conetnt;
        padding-left: 10px;
        padding-right: 10px;
        outline: none;
        color: #FFFF;
        border: none;
        border-radius: 3px;
    }
    /* form{
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    } */
    div.style_Container__1DUEy button{
        margin-top: 20px;
        width: 250px;
        height: 40px;
        outline: none;
        color: #FFFF;
        border: none;
        border-radius: 25px;
        /* margin: 0 7px 0 7px; */
        cursor: pointer;
        font-family: 'Heebo', sans-serif;
        font-size: 25px;
    }
    div.style_Container__1DUEy button:hover{
        opacity: .9;
    }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form{
        /* width: 100vw; */
        /* border: 1px solid white; */
        display: flex;
        /* justify-content: center; */
        flex-direction: column;
        /* gap: 5px; */
        margin-top: 10px;
        align-items: center;
        height: 302px;
    }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form div.style_CCNumber__2pfKc{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #FFFFFF;
            width: 300px;
            height: 30px;
            border-radius:5px;
            font-size: 15px;
            font-weight: 300;
            color: #9b9b9b;
        }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form div.style_CCNumber__2pfKc:hover{
            cursor: default;
        }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form div.style_CVV__1vB7H input{
                /* display: flex; */
                /* justify-content: flex-end;
                 */
                padding-left: 10px;
                direction: ltr;
                align-items: center;
                background-color: #FFFFFF;
                width: 120px;
                height: 30px;
                border-radius:5px;
                font-size: 15px;
                font-weight: 300;
                /* color: #9b9b9b; */
            }
    /* div.CVV:hover{
            input{
                cursor: default;
            }
        } */
    div.style_Container__1DUEy div.style_InputFields__2dkrE form input, div.style_Container__1DUEy div.style_InputFields__2dkrE form select{
            display: inline-block;
            width: 300px;
            height: 25px;
            border:none;
            outline: none;
            /* border-width: 0px; */
            border-radius: 3px;
            resize: none;
            overflow: auto;
            font-family: arial;
        }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form input::-webkit-input-placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
            padding-right: 0.3rem;
            color: #adabab;
            opacity: 1; /* Firefox */
        }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form input:-ms-input-placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
            padding-right: 0.3rem;
            color: #adabab;
            opacity: 1; /* Firefox */
        }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form input::placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
            padding-right: 0.3rem;
            color: #adabab;
            opacity: 1; /* Firefox */
        }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form input:-ms-input-placeholder{ /* Internet Explorer 10-11 */
            padding-right: 0.3rem;
            color: #adabab;
        }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form input::-ms-input-placeholder{ /* Microsoft Edge */
            padding-right: 0.3rem;
            color: #adabab;
        }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form div.style_ValidInfo__2qSvU{
            width: 300px;
            display: flex;
            /* gap: 6px; */
            justify-content: space-between;
        }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form div.style_ValidInfo__2qSvU input:nth-child(3){
                direction: ltr;
                padding-left: 0.3rem;
            }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form div.style_ValidInfo__2qSvU > * + *{
            margin-right: 6px;
        }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form div.style_BottomBtns__2r9GB{
            display: flex;
            flex-direction: column;
            /* gap: 15px; */
            /* margin-top: 70px; */
            /* margin-top: 70px; */
            position: absolute;
            bottom: 0;
        }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form div.style_BottomBtns__2r9GB button{
                width: 350px;
                height: 35px;
                border-radius: 30px;
                outline: none;
                border:none;
                color: #FFFF;
                font-size: 18px;
            }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form div.style_BottomBtns__2r9GB button:nth-child(2){
                margin-top: 15px;
                background-color: unset;
                width: 350px;
                height: 35px;
                border-radius: 30px;
                outline: none;
                border: 2px solid rgb(255, 255, 255, 0.3);
                color: #FFFF;
                font-size: 18px;
                font-weight: 530;
            }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form div.style_BottomBtns__2r9GB button:nth-child(3){
                background-color: unset;
                width: 350px;
                height: 35px;
                border-radius: 30px;
                outline: none;
                border: 2px solid rgb(255, 255, 255, 0.3);
                color: #FFFF;
                font-size: 18px;
                font-weight: 530;
            }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form div.style_BottomBtns__2r9GB button:hover{
                cursor: pointer;
            }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form div.style_BottomBtns__2r9GB > * + *{
            margin-top: 15px;
        }
    div.style_Container__1DUEy div.style_InputFields__2dkrE form > * + *{
        margin-top: 5px;
    }

@media (max-width: 850px){
    div.style_Container__1DUEy{
        width: 75vw;
    }
        div.style_Container__1DUEy div.style_InputFields__2dkrE form{
            display: flex;
            /* justify-content: center; */
            flex-direction: column;
            /* gap: 5px; */
            margin-top: 10px;
            align-items: center;
            height: 302px;


        }
            
   
            div.style_Container__1DUEy div.style_InputFields__2dkrE form input, div.style_Container__1DUEy div.style_InputFields__2dkrE form select{
                width: 220px;
            }

            div.style_Container__1DUEy div.style_InputFields__2dkrE form div.style_ValidInfo__2qSvU{
                width: 220px;
            }    

            div.style_Container__1DUEy div.style_InputFields__2dkrE form div.style_BottomBtns__2r9GB{
                display: flex;
                flex-direction: column;
                /* gap: 15px; */
                /* margin-top: 70px; */
                position: absolute;
                bottom: 0;
    
            }
    
                div.style_Container__1DUEy div.style_InputFields__2dkrE form div.style_BottomBtns__2r9GB button{
                    width: 250px;
                    height: 30px;
                }
                
                div.style_Container__1DUEy div.style_InputFields__2dkrE form div.style_BottomBtns__2r9GB button:nth-child(2){
                    width: 250px;
                    height: 30px;
                }
                div.style_Container__1DUEy div.style_InputFields__2dkrE form div.style_BottomBtns__2r9GB button:nth-child(3){
                    width: 250px;
                    height: 30px;
                }
}
div.style_Container__NtV-k{
    display: flex;
    justify-conetnt: center;
    align-items: center;
    flex-direction: column;

}

    div.style_Container__NtV-k div.style_Title__Jn_6S{
        font-size: 25px;
        font-weight: 700;
    }

    div.style_Container__NtV-k div.style_Body__1xsKB{
        /* margin-top: 15px; */
        display: flex;
        justify-conetnt: center;
        align-items: center;
        flex-direction: column;
    }

    div.style_Container__NtV-k div.style_Body__1xsKB span{
            color: #FF0000;
            font-weight: 400;
            
        }

    div.style_Container__NtV-k div.style_Button__1c89V{
        margin-top: 25px;
        border: 1px solid #000000;
        /* padding: 5px; */
        padding-left: 40px;
        padding-right: 40px;
        border-radius: 20px;
    }

    div.style_Container__NtV-k div.style_Button__1c89V:hover{
        cursor: pointer;
        color: #FFFFFF;
        background-color: #000000;
    }
div.style_Container__1Wu9y{
    display: flex;
    justify-conetnt: center;
    align-items: center;
    flex-direction: column;

}

    div.style_Container__1Wu9y div.style_Title__1Pq95{
        font-size: 25px;
        font-weight: 700;
    }

    div.style_Container__1Wu9y div.style_Body__2mF89{
        /* margin-top: 15px; */
        display: flex;
        justify-conetnt: center;
        align-items: center;
        flex-direction: column;
    }

    div.style_Container__1Wu9y div.style_Body__2mF89 span{
            color: #FF0000;
            font-weight: 400;
            
        }

    div.style_Container__1Wu9y div.style_Button__1pey6{
        margin-top: 25px;
        border: 1px solid #000000;
        /* padding: 5px; */
        padding-left: 40px;
        padding-right: 40px;
        border-radius: 20px;
    }

    div.style_Container__1Wu9y div.style_Button__1pey6:hover{
        cursor: pointer;
        color: #FFFFFF;
        background-color: #000000;
    }
.style_container__FPf9t{
    margin: auto;
    text-align: center;
}
div.style_Container__3dpgT{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
    position: relative;
}

    div.style_Container__3dpgT .style_TitleText__saZbJ{
        /* border: 6px solid blue; */
        text-align: center;
        height: -webkit-max-content;
        height: -moz-max-content;
        height: max-content;
        color: #FFFF;
        margin: 30px;
        font-weight: 400;
        font-size: 20px;
        font-family: 'Heebo', sans-serif;
    }

    div.style_Container__3dpgT .style_InputFields__1ThEw{
        /* border: 2px solid green; */
        height: 100%;
    }

    div.style_Container__3dpgT .style_InputFields__1ThEw form{
            /* border: 4px solid red; */
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }

    div.style_Container__3dpgT .style_InputFields__1ThEw form .style_Inputs__11nOk{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

    div.style_Container__3dpgT .style_InputFields__1ThEw form .style_Inputs__11nOk > * + *{
                margin-top: 6px;
            }

    div.style_Container__3dpgT .style_InputFields__1ThEw form input{
                display: inline-block;
                width: 100%;
                height: 30px;
                border:none;
                outline: none;
                /* border-width: 0px; */
                border-radius: 3px;
                resize: none;
                overflow: auto;
                font-family: 'Heebo', sans-serif;
                padding: 2px 5px 2px 5px;
            }

    div.style_Container__3dpgT .style_InputFields__1ThEw form input::-webkit-input-placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #adabab;
                opacity: 1; /* Firefox */
            }

    div.style_Container__3dpgT .style_InputFields__1ThEw form input:-ms-input-placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #adabab;
                opacity: 1; /* Firefox */
            }

    div.style_Container__3dpgT .style_InputFields__1ThEw form input::placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #adabab;
                opacity: 1; /* Firefox */
            }

    div.style_Container__3dpgT .style_InputFields__1ThEw form input:-ms-input-placeholder{ /* Internet Explorer 10-11 */
                color: #adabab;
            }

    div.style_Container__3dpgT .style_InputFields__1ThEw form input::-ms-input-placeholder{ /* Microsoft Edge */
                color: #adabab;
            }

    div.style_Container__3dpgT .style_InputFields__1ThEw form div.style_CheckTerm__2D_t3{
                /* border: 1px solid blue; */
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

    div.style_Container__3dpgT .style_InputFields__1ThEw form div.style_CheckTerm__2D_t3 input{
                    width: 15px;
                }

    div.style_Container__3dpgT .style_InputFields__1ThEw form div.style_CheckTerm__2D_t3 span{
                    width: 20rem;
                    font-size: 17px;
                    /* font-weight: 600; */
                    color: rgb(255,255,255)
                }

    div.style_Container__3dpgT .style_InputFields__1ThEw form div.style_CheckTerm__2D_t3 .style_TextTermBtn__-HwJ6{
                    font-weight: 900;
                    text-decoration: underline;
                    color: rgb(255,255,255,0.65);
                }

    div.style_Container__3dpgT .style_InputFields__1ThEw form div.style_CheckTerm__2D_t3 .style_TextTermBtn__-HwJ6:hover{
                    cursor: pointer;
                }

    div.style_Container__3dpgT .style_InputFields__1ThEw form .style_Btns__13h2y{
                display: flex;
                flex-direction: column;
            }

    /* gap: 15px; */

    div.style_Container__3dpgT .style_InputFields__1ThEw form .style_Btns__13h2y button{
                    width: 350px;
                    /* height: 50px; */
                    height: 35px;
                    border-radius: 30px;
                    outline: none;
                    border:none;
                    color: #FFFF;
                    /* font-size: 27px; */
                    font-size: 22px;
                    cursor: pointer;
                    font-family: 'Heebo', sans-serif;
                }

    div.style_Container__3dpgT .style_InputFields__1ThEw form .style_Btns__13h2y button:hover{
                    opacity: .9;
                }

    div.style_Container__3dpgT .style_InputFields__1ThEw form .style_Btns__13h2y #style_BackBtn__1-h4j{
                    background-color: unset;
                    border: 2px solid rgba(255, 255, 255, 0.3);
                }

    div.style_Container__3dpgT .style_InputFields__1ThEw form .style_Btns__13h2y > * + *{
                /* margin-top: 15px; */
                margin-top: 7px;
            }

    div.style_Container__3dpgT div.style_InputFields__1ThEw form > * + *{
        margin-top: 6px;
    }

@media (max-width: 850px){
        div.style_Container__3dpgT .style_TitleText__saZbJ{
            /* padding-top: 15px; */
            padding-bottom: 15px;
            margin-bottom: 0;
            font-size: 90%;
        }
            div.style_Container__3dpgT div.style_InputFields__1ThEw form input{
                width: 250px;
            }
            div.style_Container__3dpgT div.style_InputFields__1ThEw form div.style_CheckTerm__2D_t3{
                width: 250px;
            }
                div.style_Container__3dpgT div.style_InputFields__1ThEw form div.style_CheckTerm__2D_t3 span{
                }
                div.style_Container__3dpgT div.style_InputFields__1ThEw form div.style_CheckTerm__2D_t3 input{
                    width: 10%;
                }
    
            div.style_Container__3dpgT div.style_InputFields__1ThEw form .style_Btns__13h2y{
                margin-top: 25px;
            }
                div.style_Container__3dpgT div.style_InputFields__1ThEw form .style_Btns__13h2y button{
                    width: 250px;
                    height: 30px;
                    font-size: 19px;
                }
            div.style_Container__3dpgT div.style_InputFields__1ThEw form .style_Btns__13h2y > * + *{
                margin-top: 10px;
            }
}
.style_container__3wrIo{
  min-height: 80vh;
  display: flex;
  justify-content: space-around;
}
.style_TopDiv__JSB3Q {
    text-align: center;
    direction: ltr !important;
    font-size: 2em;
    color: white;
    background-color: red;
    align-self: center;
    padding: 15px;
    border-radius: var(--brdRadius);
}

@media screen and (max-width: 600px) {
    .style_TopDiv__JSB3Q {
      display: flex;
      flex-flow: column-reverse wrap;
    }
  }
  
div.style_ModalContainer__3Goeh{
    /* margin: 0;
    padding: 0;
    background: url(../../../assets/welcome_bg.png) no-repeat;
    background-size: 100%;
    background-position: bottom;
    background-color: #FFFF;
    min-height: 100%; */


    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
    /* gap: 18px; */
    div.style_ModalContainer__3Goeh h3{
        text-align: center;
        width: 80%;
        font-size: 23px;
    }
    div.style_ModalContainer__3Goeh button{
        margin-top: 18px;
        font-family: Heebo;
        outline: none;
        /* border: none; */
        width: 185px;
        height: 45px;
        /* border: 1px solid #00b1e1; */
        border-radius: 25px;
        font-size: 18px;
        align-text: center;
        font-weight: 600;
        /* color: #00b1e1; */
        background-color: unset;
        cursor: pointer;
    }
    div.style_ModalContainer__3Goeh h3{
        text-overflow: ellipsis;
    }
div.style_Container__2z5Gz{
    /* border: 4px solid blue; */
    width: 100%;
    height: 100%;
    padding-right: 1%;
    padding-left: 1%;
    background-color: #000;

    display: flex;
    align-items: center;
    overflow: hidden;

}
    /* justify-content: space-between; */
    div.style_Container__2z5Gz div.style_MenuBtns__1Q9gp{
        /* border: 1px solid red; */
        display: flex;
        /* flex-flow: row-reverse wrap; */
        flex-flow: row-reverse;
        align-items: center;
        justify-content: start;
        width: 45%;
        height: 90%;
        overflow: hidden;
    }
    div.style_Container__2z5Gz div.style_MenuBtns__1Q9gp div.style_menubtn__nWDY5 {
            padding: 10px;
            background-color: transparent;
            border: 0px;
            color: var(--mainWhite);
            font-size: 1.3vw;
            font-weight: 200;
            cursor: pointer;
        }
    div.style_Container__2z5Gz div.style_MenuBtns__1Q9gp div.style_menubtn__nWDY5.style_menubtnActive__3Awcq {
                font-weight: 400;
                -webkit-text-decoration: underline var(--mainWhite);
                        text-decoration: underline var(--mainWhite);
            }
    div.style_Container__2z5Gz div.style_BigappsLogo__2oNfJ{
        /* border: 1px solid red; */
        height: 90%;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        margin-left: auto;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    div.style_Container__2z5Gz div.style_BigappsLogo__2oNfJ *:hover{
            opacity: .9;
        }
    div.style_Container__2z5Gz div.style_MobileBtns__2gouv{
        /* border: 2px solid red; */
        height: 77%;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;

        display: flex;
        justify-content: end;
        align-items: center;
    }
    div.style_Container__2z5Gz div.style_MobileBtns__2gouv *{
            margin-left: 7%;
            cursor: pointer;
        }
    div.style_Container__2z5Gz div.style_MobileBtns__2gouv *:hover{
            opacity: .8;
        }
:root {
    --start-color: white;
    --bg-image: url(/static/media/bg.dbb45466.jpg) no-repeat #FFFFFF;
}
div.style_Container__1rl1g{
    margin: 0;
    padding: 0;
    background: url(/static/media/bg.dbb45466.jpg) no-repeat #FFFFFF;
    background: var(--bg-image);
    background-size: auto;
    /* background-size: resize; */
    background-position: top;
    /* height: 100vh; */

    height: 100vh;
    max-height: 100%;
    overflow:auto;
}
div.style_Container__1rl1g div.style_TopMain__1GzvF{
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px;
    }
div.style_Container__1rl1g div.style_TopMain__1GzvF div.style_TopRightBtns__3GZkO{
            display: flex;
            position: absolute;
            right: 7vw;
        }
/* gap: 20px; */
div.style_Container__1rl1g div.style_TopMain__1GzvF div.style_TopRightBtns__3GZkO div.style_FilterBtn__2EpSO{
                border: 2px solid #FFFFFF;
                border-radius: 50px;
                padding: 5px 6px 0px 5.5px;
                background-color: white;
                background-color: var(--start-color);
            }
div.style_Container__1rl1g div.style_TopMain__1GzvF div.style_TopRightBtns__3GZkO div.style_FilterBtn__2EpSO img{
                    width: 18px;
                }
div.style_Container__1rl1g div.style_TopMain__1GzvF div.style_TopRightBtns__3GZkO div.style_CancleFilterBtn__2sBX4{
                margin-right: 20px;
                width: 125px;
                height: 28px;
                display: flex;
                justify-content: center !important;
                /* gap: 10px; */
                border-radius: 20px;
                /* background-color: #00b1e1; */
                vertical-align: baseline;
            }
div.style_Container__1rl1g div.style_TopMain__1GzvF div.style_TopRightBtns__3GZkO div.style_CancleFilterBtn__2sBX4 img{
                    margin-left: 10px;
                    margin-top: 1px;
                    width: 25px;
                }
div.style_Container__1rl1g div.style_TopMain__1GzvF div.style_TopRightBtns__3GZkO div.style_CancleFilterBtn__2sBX4 span{
                    margin-top: 3px;
                    font-size: 16px;
                    color: #FFFF;
                }
div.style_Container__1rl1g div.style_TopMain__1GzvF div.style_TopRightBtns__3GZkO div.style_DisabledCancleFilterBtn__EKwVT {}
div.style_Container__1rl1g div.style_TopMain__1GzvF div.style_TopRightBtns__3GZkO div.style_FilterBtn__2EpSO:hover, div.style_Container__1rl1g div.style_TopMain__1GzvF div.style_TopRightBtns__3GZkO div.style_CancleFilterBtn__2sBX4:hover{
                cursor: pointer;
            }
div.style_Container__1rl1g div.style_TopMain__1GzvF div.style_Title__1Arjs{
            margin-right: 40px;
            font-size: 30px;
            font-weight: 550;
            color: #FFFFFF;
        }
div.style_Container__1rl1g div.style_ScreenBody__315Bg{
        padding: 0;
        margin: 0;
        padding-bottom: 50px;

        display: flex;
        justify-content: center;


        -webkit-column-count: 2;
             column-count: 2;
    }
div.style_Container__1rl1g div.style_ScreenBody__315Bg div.style_StoreList__2eYKL{
            height: 100%;
            max-width: 1000px;
            min-width: 360px;

            display: flex;
            flex-wrap: wrap;
            /* gap: 10px; */
            justify-content: center;
            /* padding-bottom: 100%; */
        }
div.style_Container__1rl1g div.style_ScreenBody__315Bg div.style_StoreList__2eYKL > * {
            padding: 5px;
        }
div.style_Container__1rl1g div.style_ScreenBody__315Bg div.style_Oops__3tJ19{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 30vh;
            font-size: 27px;
        }
@media (max-width: 1700px) {
                div.style_Container__1rl1g div.style_TopMain__1GzvF div.style_TopRightBtns__3GZkO div.style_FilterBtn__2EpSO{
                    background: transparent;
                /* background-color: white; */
                }
}
@media (max-width: 800px){
    div.style_Container__1rl1g{
        margin: 0;
        padding: 0;
        background: url(/static/media/bg.dbb45466.jpg) no-repeat #FFFFFF;
        background: var(--bg-image);
        background-size: 1200px;
        /* background-size: 210vh; */
        background-position: top;
        background-color: #FFFFFF;
    }
                div.style_Container__1rl1g div.style_TopMain__1GzvF div.style_TopRightBtns__3GZkO div.style_FilterBtn__2EpSO{
                    border: 2px solid #FFFFFF;
                    border-radius: 50px;
                    padding: 5px 6px 0px 5.5px;
                    background: transparent;
                }
                    /* background-color: white; */
                    div.style_Container__1rl1g div.style_TopMain__1GzvF div.style_TopRightBtns__3GZkO div.style_FilterBtn__2EpSO img{
                        width: 28px;
                    }

                div.style_Container__1rl1g div.style_TopMain__1GzvF div.style_TopRightBtns__3GZkO div.style_CancleFilterBtn__2sBX4{
                    width: 125px;
                    height: 40px;
                    display: flex;
                    justify-content: start !important;
                    padding-right: 10px;
                    /* gap: 10px; */
                    border-radius: 30px 0 0 30px;
                    box-shadow: 4px 4px #00000050;
                    /* background-color: #00b1e1; */
                    vertical-align: baseline;
                    align-items: center;

                    position: fixed;
                    right: -22px;
                    bottom: 5vh !important;
                }

                    div.style_Container__1rl1g div.style_TopMain__1GzvF div.style_TopRightBtns__3GZkO div.style_CancleFilterBtn__2sBX4 img{
                        margin-top: 5px;
                        width: 25px;
                    }
                    div.style_Container__1rl1g div.style_TopMain__1GzvF div.style_TopRightBtns__3GZkO div.style_CancleFilterBtn__2sBX4 span{
                        margin-top: 1px;
                        /* margin-right: 3px; */
                        font-size: 16px;
                        color: #FFFF;
                    }
            
            div.style_Container__1rl1g div.style_TopMain__1GzvF div.style_Title__1Arjs{
                margin-right: 40px;
                font-size: 26px;
                font-weight: 550;
                color: #FFFFFF;
            }
            div.style_Container__1rl1g div.style_ScreenBody__315Bg div.style_Oops__3tJ19{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 22vh;
                font-size: 24px;
            }
}
div.style_ModalContainer__2jKxo{
    display: flex;
    flex-direction: column;
    align-items: center;
}
    /* gap: 40px; */
    div.style_ModalContainer__2jKxo div.style_Tags__28EKz{
        width: 80%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        overflow: auto;
        /* gap: 120px; */
        margin: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        max-height: 400px;
        /* border: 1px solid red; */
        -webkit-column-width: max-content;
                column-width: max-content;
    }
    /* column-count: 3; */
    div.style_ModalContainer__2jKxo div.style_Tags__28EKz div.style_Tag__2S4CG{
            margin-left: 80px;
            display: block;
            width: -webkit-max-content;
            width: -moz-max-content;
            width: max-content;
            border: 1px solid #FFFF;
            border-radius: 20px;
            color: #FFFF;
            margin-bottom: 13px;
        }
    div.style_ModalContainer__2jKxo div.style_Tags__28EKz div.style_Tag__2S4CG span{
                margin: 10px;
                font-size: 20px;
            }
    div.style_ModalContainer__2jKxo div.style_Tags__28EKz div.style_Tag__2S4CG:hover{
            cursor: pointer;
        }
    div.style_ModalContainer__2jKxo div.style_ConfirmBtn__1qAY5{
        margin-top: 40px;
        display: flex;
        justify-content: center;
    }
    div.style_ModalContainer__2jKxo div.style_ConfirmBtn__1qAY5 button{
            outline: none;
            border: 1px solid #FFFF;
            color: #00b1e1;
            background-color: #FFFF;
            border-radius: 20px;
            width: 190px;
            height: 35px;
            font-size: 25px;
            font-weight:unset;
        }
    div.style_ModalContainer__2jKxo div.style_ConfirmBtn__1qAY5 button:hover{
            cursor: pointer;
        }

@media (max-width: 800px){
        div.style_ModalContainer__2jKxo div.style_ConfirmBtn__1qAY5{
            margin-top: 0px;

        }
        div.style_ModalContainer__2jKxo{
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        height: 100%;
    }
        /* gap: 2vh; */

        div.style_ModalContainer__2jKxo div.style_Tags__28EKz{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: start;
            flex-wrap: nowrap;

            /* gap: 1px; */
            height: 80vh;
            width: 100vw;
            overflow: auto;
            
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }
    
    div.style_Tags__28EKz::-webkit-scrollbar{
        display: none;
    }
}
div.style_Container__1yxuD{
    padding: 5%;
    text-align: center;
    width: 210px;
    height: 200px;
    border-radius: 5px;
    background-color: #FFFF;
    box-shadow: 3px 3px 12px rgb(202, 202, 202, 0.45), inset -1px -1px 4px rgb(202, 202, 202,0.66) ;
    position: relative;

}

    div.style_Container__1yxuD .style_redCircle__15Bdl{
        border: .1vw solid #ff0000;
        border-radius: 100%;
        position: relative;
        position: absolute;
        left: 1vh;
        top: 1vh;
        width: 1.2em; height: 1.2em;
    }

    div.style_Container__1yxuD .style_redCircle__15Bdl::before{
        content: "";
        display: block;
        position: absolute;
        width: .85em; height: .85em;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ff0000;
        border-radius: 100%;
    }

    div.style_Container__1yxuD .style_greenCircle__LFrv2{
        border: .1vw solid #1bb449;
        border-radius: 100%;
        position: relative;
        position: absolute;
        left: 1vh;
        top: 1vh;
        width: 1.2em; height: 1.2em;
    }

    div.style_Container__1yxuD .style_greenCircle__LFrv2::before{
        content: "";
        display: block;
        position: absolute;
        width: .85em; height: .85em;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        background-color: #1bb449;
        border-radius: 100%;
    }

    div.style_Container__1yxuD div.style_ShopImage__2fPyP img{
            max-width: 65%;
        }

    div.style_Container__1yxuD div.style_NameAndAddress__2AQsE div.style_ShopName__1mPVR span{
                font-size: 15px;
                font-weight: 500;
                text-overflow: ellipsis;
            }

    div.style_Container__1yxuD div.style_NameAndAddress__2AQsE div.style_shopType__3PGi8{
            font-size: 12px;
        }

    div.style_Container__1yxuD div.style_NameAndAddress__2AQsE div.style_ShopAddress__37g4m{
            display: flex;
            justify-content: center;
            align-items: center;
    

        }

    /* gap: 5px; */

    div.style_Container__1yxuD div.style_NameAndAddress__2AQsE div.style_ShopAddress__37g4m div.style_ShopIconAddress__s8PxA{
                margin-left: 5px;
            }

    div.style_Container__1yxuD div.style_NameAndAddress__2AQsE div.style_ShopAddress__37g4m div.style_ShopIconAddress__s8PxA img{
                    width: 10px;
                }

    div.style_Container__1yxuD div.style_NameAndAddress__2AQsE div.style_ShopAddress__37g4m div.style_ShopTextAddress__3zStd span{
                    font-size: 10px;
                    text-overflow: ellipsis;
                }

    div.style_Container__1yxuD div.style_DeliveryTime__1kiCC{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size:12px;
    }

    div.style_Container__1yxuD div.style_DeliveryTime__1kiCC div.style_bikeImg__30is4{
            margin-left: 7px;
            width: 25px;
            /* height: 20px; */
        }

div.style_Container__1yxuD:hover{
    cursor: pointer;
    box-shadow: 3px 3px 12px rgb(202, 202, 202, 0.45), inset -10px -10px 15px rgb(202, 202, 202,0.16) ;
}


@media (max-width: 550px){
    div.style_Container__1yxuD{
        /* background-color: red; */
        width: 150px;
        height: 140px;

    }
}
div.style_ModalContainer__29R2k{
    /* border: 3px solid red; */
    /* padding: 0 150px 0 150px; */
    width: 500px;
    height: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Heebo', sans-serif;
}
    

    div.style_ModalContainer__29R2k div.style_closeBtn__2792p{
        /* border: 1px solid red; */
        margin-right: auto;
        margin-left: 5%;
        cursor: pointer;
        font-size: 30px;
        font-weight: 400;
    }
    

    div.style_ModalContainer__29R2k h3{
        text-align: center;
        width: 80%;
        font-size: 25px;
        font-weight: 500;
        font-family: 'Heebo', sans-serif;
    }
    

    div.style_ModalContainer__29R2k div.style_Inputs__2JqHt{
        /* border: 1px solid green; */
        outline: none;
        width: 100%;
    }
    

    div.style_ModalContainer__29R2k div.style_Inputs__2JqHt div.style_errorMsg__2lwro{
            /* border: 1px solid red; */
            text-align: center;
            height: 30px;
            padding-top: 5px;
            color: #FF0000;
        }
    

    div.style_ModalContainer__29R2k div.style_Inputs__2JqHt * > *{
            margin-right: 10px;
        }
    

    div.style_ModalContainer__29R2k div.style_Btns__3kY0_{
        /* border: 3px solid blue; */
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    

    div.style_ModalContainer__29R2k div.style_Btns__3kY0_ button{
            font-family: 'Heebo', sans-serif;
            cursor: pointer;
            background-color: unset;
            border-radius: 30px;
            height: 45px;
            width: 120px;
            font-size: 22px;
        }
    

    div.style_ModalContainer__29R2k div.style_Btns__3kY0_ > * + *{
        margin-right: 30px;
    }

@media (max-width: 520px){
    div.style_ModalContainer__29R2k{
        width: 350px;
        height: 190px;
    }

        div.style_ModalContainer__29R2k div.style_closeBtn__2792p{
            font-size: 25px;
        }

        div.style_ModalContainer__29R2k h3{
            font-size: 25px;
        }
            div.style_ModalContainer__29R2k div.style_Inputs__2JqHt div.style_errorMsg__2lwro{
                font-size: 12px;
            }

        div.style_ModalContainer__29R2k div.style_Btns__3kY0_{
            margin-top: 0px;
            margin-bottom: 10px;
        }

            div.style_ModalContainer__29R2k div.style_Btns__3kY0_ button{
                height: 37px;
                width: 110px;
                font-size: 18px;
            }
}
div.style_Container__1gRX0{
    margin: 0 auto;
    padding: 0;
    background: url(/static/media/toskana_bg.571cbc6f.png) no-repeat #FFFFFF;
    background-size: 1150px;
    /* background-size: resize; */
    background-position: top;
    /* height: 100vh; */

    height: 100vh;
    max-height: 100%;
    overflow:auto;
}

    div.style_Container__1gRX0 div.style_TopMain__1F05k{
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px;
    }

    div.style_Container__1gRX0 div.style_TopMain__1F05k div.style_Title__25uxH{
            margin-right: 40px;
            font-size: 30px;
            font-weight: 550;
            color: #FFFFFF;
        }

    div.style_Container__1gRX0 div.style_ScreenBody__1ux1G{
        padding: 0;
        margin: 0;
        padding-bottom: 50px;

        display: flex;
        justify-content: center;


        -webkit-column-count: 2;
             column-count: 2;
    }

    div.style_Container__1gRX0 div.style_ScreenBody__1ux1G div.style_StoreList__2o9F4{
            height: 100%;
            max-width: 1000px;
            min-width: 360px;

            display: flex;
            flex-wrap: wrap;
            /* gap: 10px; */
            justify-content: center;
            /* padding-bottom: 100%; */
        }

    div.style_Container__1gRX0 div.style_ScreenBody__1ux1G div.style_StoreList__2o9F4 > * {
            padding: 15px;
        }

/* @media (max-width: 800px){
    div.Container{
        margin: 0;
        padding: 0;
        background: url(../../assets/bg.jpg) no-repeat;
        background-size: 1200px;
        background-position: top;
        background-color: #FFFFFF;
        
        div.TopMain{
            div.Title{
                margin-right: 40px;
                font-size: 26px;
                font-weight: 550;
                color: #FFFFFF;
            }
        }
    }
} */
div.style_Container__2SeFk{
    margin: 0;
    padding: 0;
    height: 300px;
    width: 350px;
    cursor: pointer;
}
    /* border: 6px solid black; */
    div.style_Container__2SeFk div.style_Wrapper__15V8F{
        /* border: 5px solid red; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
    }
    div.style_Container__2SeFk div.style_Wrapper__15V8F img.style_BgStore__3ynkZ{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    div.style_Container__2SeFk div.style_Wrapper__15V8F div.style_NameAndAddress__SXXKb{
            /* border: 5px solid green; */
            z-index: 10;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            
            width: 95%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    div.style_Container__2SeFk div.style_Wrapper__15V8F div.style_NameAndAddress__SXXKb div.style_ShopName__3_tEj span{
                    font-family: Heebo,Regular;
                    font-size: 30px;
                    font-weight: 400;

                }
    div.style_Container__2SeFk div.style_Wrapper__15V8F div.style_NameAndAddress__SXXKb div.style_ShopAddress__1Lk9M{
                margin-top: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
    div.style_Container__2SeFk div.style_Wrapper__15V8F div.style_NameAndAddress__SXXKb div.style_ShopAddress__1Lk9M div.style_ShopIconAddress__iPxqD{
                    margin-left: 10px;
                    margin-top: 3px;
                }
    div.style_Container__2SeFk div.style_Wrapper__15V8F div.style_NameAndAddress__SXXKb div.style_ShopAddress__1Lk9M div.style_ShopIconAddress__iPxqD img{
                        width: 15px;
                    }
    div.style_Container__2SeFk div.style_Wrapper__15V8F div.style_NameAndAddress__SXXKb div.style_ShopAddress__1Lk9M div.style_ShopTextAddress__5hnu8 span{
                        font-family: Heebo,Regular;
                        font-size: 20px;
                        color: #808080;
                        text-overflow: ellipsis;
                    }
    div.style_Container__2SeFk div.style_Wrapper__15V8F div.style_ShopImage__a4ovX{
            /* border: 5px solid brown; */
            z-index: 10;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
        }
    div.style_Container__2SeFk div.style_Wrapper__15V8F div.style_ShopImage__a4ovX img{
                margin-top: 15px;
                width: 70%;
            }

div.style_Container__2danR{
    margin: 0 auto;
    padding: 0;
    /* background: url(../../assets/scooty_images/scooty_bg.jpg); */
    background-repeat: no-repeat;
    background-color: #FFFFFF;
    /* background-size: 1150px; */
    background-size: resize; 
    background-position: top;
    /* height: 100vh; */

    height: 100vh;
    max-height: 100%;
    overflow:auto;
}

    div.style_Container__2danR div.style_TopMain__2XCjp{
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px;
    }

    div.style_Container__2danR div.style_TopMain__2XCjp div.style_Title__3eKUg{
            margin-right: 40px;
            font-size: 30px;
            font-weight: 550;
            color: #FFFFFF;
        }

    div.style_Container__2danR div.style_ScreenBody__2--Bw{
        padding: 0;
        margin: 0;
        padding-bottom: 50px;

        display: flex;
        justify-content: center;


        -webkit-column-count: 2;
             column-count: 2;
    }

    div.style_Container__2danR div.style_ScreenBody__2--Bw div.style_StoreList__3D6Ff{
            height: 100%;
            max-width: 1000px;
            min-width: 360px;

            display: flex;
            flex-wrap: wrap;
            /* gap: 10px; */
            justify-content: center;
            /* padding-bottom: 100%; */
        }

    div.style_Container__2danR div.style_ScreenBody__2--Bw div.style_StoreList__3D6Ff > * {
            padding: 15px;
        }

/* @media (max-width: 800px){
    div.Container{
        margin: 0;
        padding: 0;
        background: url(../../assets/bg.jpg) no-repeat;
        background-size: 1200px;
        background-position: top;
        background-color: #FFFFFF;
        
        div.TopMain{
            div.Title{
                margin-right: 40px;
                font-size: 26px;
                font-weight: 550;
                color: #FFFFFF;
            }
        }
    }
} */
div.style_Container__1yBHM{
    padding: 5%;
    text-align: center;
    width: 300px;
    height: 315px;
    border-radius: 5px;
    background-color: #FFFF;
    box-shadow: 3px 3px 12px rgb(202, 202, 202, 0.45), inset -1px -1px 4px rgb(202, 202, 202,0.66) ;
    position: relative;

}

    div.style_Container__1yBHM .style_redCircle__1JF1Z{
        border: .1vw solid #ff0000;
        border-radius: 100%;
        position: relative;
        position: absolute;
        left: 1vh;
        top: 1vh;
        width: 1.2em; height: 1.2em;
    }

    div.style_Container__1yBHM .style_redCircle__1JF1Z::before{
        content: "";
        display: block;
        position: absolute;
        width: .85em; height: .85em;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        background-color: #ff0000;
        border-radius: 100%;
    }

    div.style_Container__1yBHM .style_greenCircle__3IkyI{
        border: .1vw solid #1bb449;
        border-radius: 100%;
        position: relative;
        position: absolute;
        left: 1vh;
        top: 1vh;
        width: 1.2em; height: 1.2em;
    }

    div.style_Container__1yBHM .style_greenCircle__3IkyI::before{
        content: "";
        display: block;
        position: absolute;
        width: .85em; height: .85em;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        background-color: #1bb449;
        border-radius: 100%;
    }

    div.style_Container__1yBHM div.style_ShopImage__myGvM{
        margin-top: 10px;
        margin-bottom: 10px;
    }

    div.style_Container__1yBHM div.style_ShopImage__myGvM img{
            max-width: 65%;
        }

    div.style_Container__1yBHM div.style_NameAndAddress__36JD3 div.style_ShopName__2IaHA span{
                font-size: 20px;
                font-weight: bold;
                text-overflow: ellipsis;
            }

    div.style_Container__1yBHM div.style_NameAndAddress__36JD3 div.style_shopType__300M1{
            font-size: 12px;
        }

    div.style_Container__1yBHM div.style_NameAndAddress__36JD3 div.style_ShopAddress__1TRQC{
            display: flex;
            justify-content: center;
            align-items: center;
    

        }

    div.style_Container__1yBHM div.style_NameAndAddress__36JD3 div.style_ShopAddress__1TRQC div.style_ShopIconAddress__2Zv5g{
                margin-left: 5px;
            }

    div.style_Container__1yBHM div.style_NameAndAddress__36JD3 div.style_ShopAddress__1TRQC div.style_ShopIconAddress__2Zv5g img{
                    width: 10px;
                }

    div.style_Container__1yBHM div.style_NameAndAddress__36JD3 div.style_ShopAddress__1TRQC div.style_ShopTextAddress__2UYhh span{
                    font-size: 20px;
                    text-overflow: ellipsis;
                }

    div.style_Container__1yBHM div.style_DeliveryTime__tZlQR{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size:20px;
    }

    div.style_Container__1yBHM div.style_DeliveryTime__tZlQR div.style_bikeImg__3-hgk{
            margin-left: 7px;
            width: 25px;
            /* height: 20px; */
        }

div.style_Container__1yBHM:hover{
    cursor: pointer;
    box-shadow: 3px 3px 12px rgb(202, 202, 202, 0.45), inset -10px -10px 15px rgb(202, 202, 202,0.16) ;
}


@media (max-width: 550px){
    div.style_Container__1yBHM{
        /* background-color: red; */
        width: 150px;
        height: 190px;

    }
}
div.style_Container__jTZJP{
    margin: 0;
    padding: 0;
    background-color: #DEDEE0;

    height: 100vh;
    max-height: 100%;
    overflow:auto;
}

    div.style_Container__jTZJP div.style_Whatsapp__BLYqS{
        cursor: pointer;
        position: fixed;
        left: 15px;
        bottom: 60px;
        z-index: 1;
    }

    div.style_Container__jTZJP div.style_Whatsapp__BLYqS img{
            opacity: .9;
        }

    div.style_Container__jTZJP div.style_TopMain__S_RMy{
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px 50px 0 50px;
    }

    div.style_Container__jTZJP div.style_TopMain__S_RMy div.style_TopRightBtns__K9z9l{
            display: flex;
            position: absolute;
            right: 7vw;
        }

    /* gap: 20px; */

    div.style_Container__jTZJP div.style_TopMain__S_RMy div.style_TopRightBtns__K9z9l div.style_FilterBtn__2W0Os img{
                    width: 28px;
                }

    div.style_Container__jTZJP div.style_TopMain__S_RMy div.style_TopRightBtns__K9z9l div.style_CancleFilterBtn__3qifE{
                margin-right: 20px;
                width: 125px;
                height: 28px;
                display: flex;
                justify-content: center !important;
                /* gap: 10px; */
                border-radius: 20px;
                background-color: #00b1e1;
                vertical-align: baseline;
            }

    div.style_Container__jTZJP div.style_TopMain__S_RMy div.style_TopRightBtns__K9z9l div.style_CancleFilterBtn__3qifE img{
                    margin-left: 10px;
                    margin-top: 1px;
                    width: 25px;
                }

    div.style_Container__jTZJP div.style_TopMain__S_RMy div.style_TopRightBtns__K9z9l div.style_CancleFilterBtn__3qifE span{
                    margin-top: 3px;
                    font-size: 16px;
                    color: #FFFF;
                }

    div.style_Container__jTZJP div.style_TopMain__S_RMy div.style_TopRightBtns__K9z9l div.style_DisabledCancleFilterBtn__1hRGg {}

    div.style_Container__jTZJP div.style_TopMain__S_RMy div.style_TopRightBtns__K9z9l div.style_FilterBtn__2W0Os:hover, div.style_Container__jTZJP div.style_TopMain__S_RMy div.style_TopRightBtns__K9z9l div.style_CancleFilterBtn__3qifE:hover{
                cursor: pointer;
            }

    div.style_Container__jTZJP div.style_TopMain__S_RMy div.style_Title__3kpW6{
            margin-right: 40px;
            font-size: 30px;
            font-weight: 550;
            color: #000;
        }

    /* div.BannerContainer{
        width: 100%;
        border: 2px solid black;
        height: 140px;
        margin: 20px 0 20px 0;

        display: flex;
        justify-content: center;
        align-items: center;
    } */

    div.style_Container__jTZJP div.style_ScreenBody__t3i-N{
        padding: 0;
        margin: 0;
        padding-bottom: 50px;

        display: flex;
        justify-content: center;


        -webkit-column-count: 2;
             column-count: 2;
    }

    div.style_Container__jTZJP div.style_ScreenBody__t3i-N div.style_StoreList__325EI{
            height: 100%;
            max-width: 1000px;
            min-width: 360px;

            display: flex;
            flex-wrap: wrap;
            /* gap: 10px; */
            justify-content: center;
            /* padding-bottom: 100%; */
        }

    div.style_Container__jTZJP div.style_ScreenBody__t3i-N div.style_StoreList__325EI > * {
            padding: 5px;
        }

    div.style_Container__jTZJP div.style_ScreenBody__t3i-N div.style_Oops__3WEwK{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 30vh;
            font-size: 27px;
        }

@media (max-width: 800px){
    div.style_Container__jTZJP{
        margin: 0;
        padding: 0;

        background-color: #DEDEE0;
    }
                div.style_Container__jTZJP div.style_TopMain__S_RMy div.style_TopRightBtns__K9z9l div.style_FilterBtn__2W0Os{
                }

                div.style_Container__jTZJP div.style_TopMain__S_RMy div.style_TopRightBtns__K9z9l div.style_CancleFilterBtn__3qifE{
                    z-index: 1;
                    width: 125px;
                    height: 40px;
                    display: flex;
                    justify-content: start !important;
                    padding-right: 10px;
                    /* gap: 10px; */
                    border-radius: 30px 0 0 30px;
                    box-shadow: 4px 4px #00000050;
                    background-color: #00b1e1;
                    vertical-align: baseline;
                    align-items: center;

                    position: fixed;
                    right: -22px;
                    bottom: 5vh !important;
                }

                    div.style_Container__jTZJP div.style_TopMain__S_RMy div.style_TopRightBtns__K9z9l div.style_CancleFilterBtn__3qifE img{
                        margin-top: 5px;
                        width: 25px;
                    }
                    div.style_Container__jTZJP div.style_TopMain__S_RMy div.style_TopRightBtns__K9z9l div.style_CancleFilterBtn__3qifE span{
                        margin-top: 1px;
                        /* margin-right: 3px; */
                        font-size: 16px;
                        color: #FFFF;
                    }
            
            div.style_Container__jTZJP div.style_TopMain__S_RMy div.style_Title__3kpW6{
                margin-right: 40px;
                font-size: 26px;
                font-weight: 550;
                color: #000;
            }
            div.style_Container__jTZJP div.style_ScreenBody__t3i-N div.style_Oops__3WEwK{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 22vh;
                font-size: 24px;
            }
}
div.style_Container__1zYiK{
    padding: 5%;
    text-align: center;
    width: 210px;
    height: 200px;
    border-radius: 5px;
    background-color: #FFFF;
    box-shadow: 3px 3px 12px rgb(202, 202, 202, 0.45), inset -1px -1px 4px rgb(202, 202, 202,0.66) ;
    position: relative;

}

    div.style_Container__1zYiK div.style_info__37cP4{
        position: absolute;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        height: -webkit-max-content;
        height: -moz-max-content;
        height: max-content;
        top: 10px;
        left: 10px;

        cursor: pointer;
    }

    div.style_Container__1zYiK div.style_ShopImage__2r7g1 img{
            /* border: 1px solid red; */
            width: 70%;
            cursor: pointer;
        }

    div.style_Container__1zYiK div.style_NameAndAddress__1EYiH{
        /* border: 1px solid red; */
        cursor: pointer;
    }

    div.style_Container__1zYiK div.style_NameAndAddress__1EYiH div.style_ShopName__2gVGA span{
                font-size: 15px;
                font-weight: 500;
                text-overflow: ellipsis;
            }

    div.style_Container__1zYiK div.style_NameAndAddress__1EYiH div.style_ShopAddress__23fwg{
            display: flex;
            justify-content: center;
            align-items: center;
    

        }

    /* gap: 5px; */

    div.style_Container__1zYiK div.style_NameAndAddress__1EYiH div.style_ShopAddress__23fwg div.style_ShopIconAddress__3Qo8m{
                margin-left: 5px;
            }

    div.style_Container__1zYiK div.style_NameAndAddress__1EYiH div.style_ShopAddress__23fwg div.style_ShopIconAddress__3Qo8m img{
                    width: 10px;
                }

    div.style_Container__1zYiK div.style_NameAndAddress__1EYiH div.style_ShopAddress__23fwg div.style_ShopTextAddress__17l8M span{
                    font-size: 10px;
                    text-overflow: ellipsis;
                }

div.style_Container__1zYiK:hover{
    box-shadow: 3px 3px 12px rgb(202, 202, 202, 0.45), inset -10px -10px 15px rgb(202, 202, 202,0.16) ;
}


@media (max-width: 550px){
    div.style_Container__1zYiK{
        /* background-color: red; */
        width: 150px;
        height: 140px;

    }
}
div.style_Container__1Qsoy{
    /* border: 1px solid red; */
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}


    div.style_Container__1Qsoy div.style_cancleBtn__3zPEN{
        position: absolute;
        font-size: 25px;
        cursor: pointer;
        left: 10px;
        top: 10px;
    }


    div.style_Container__1Qsoy div.style_Content__13coQ{
        /* border: 1px solid red; */
        width: 80%;
        text-wrap: wrap;
        margin: auto;
        align-text: right;
        word-break: break-word;
    }
.slider-container{
    /* border: 5px solid red; */
    overflow: hidden;
    padding: 0;
    direction: ltr;

    margin: 20px auto;
    margin-bottom: 35px;
    width: 100%;
    height: 180px;

    /* border-radius: 10px; */
    position: relative;

    box-shadow: .2vw .25vw 6px rgba(0,0,0,.2);
}

.slider-container > .slider{
    /* border: 3px solid blue; */
    
    margin: 0;
    padding: 0;


    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    height: 100% !important;
    /* transition: transform .6s */
}


.slider > .slide{
    /* border: 3px solid green; */

    direction: ltr;
    /* object-fit: cover; */
    width: 100vw;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;

    cursor: pointer;

}

.slide > img{
    height: 100%;
    width: 100%;
    /* width: 20% !important; */
    object-fit: cover;
    
}

/* .slide > .price{
    direction: ltr;
    font-family: Georgia;
    font-weight: bold;

    display: flex;
    justify-content: center;
    align-items: center;
    
    width: 30%;
} */

/* .shekels{
    margin-bottom: 3vw;
    font-size: 10vw;
}

.agorot{
    font-size: 3vw;
    margin-bottom: 20%;
} */

@media (max-width: 750px){
    div.slider-container{
        height: 115px;
    }
}
@media (max-width: 500px){
    div.slider-container{
        height: 85px;
    }
}
@media (max-width: 350px){
    div.slider-container{
        height: 70px;
    }
}

/* .controls{
    cursor: pointer;
}

.prev{
    position: absolute;
    top: 5px;
    left: 10px;
}
.next{
    position: absolute;
    top: 5px;
    right: 10px;
} */


* {
  direction: rtl;
  box-sizing: border-box;
}

:root {
  --mainColor: gray;
  --mainWhite: white;
  --mainBg: white;
  --thinScrBg: gray;
  --brdRadius: 15px;
  --mainCol1: #F1A103;
  --blue: #1e90ff;
  --white: #ffffff;
  --black: black;
}

::-webkit-scrollbar {
  width: 2px;
}
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */
::-webkit-scrollbar-thumb {
  background: gray;
  background: var(--mainColor);
}
::-webkit-scrollbar-thumb:hover {
  background: white;
  background: var(--mainWhite);
}

img {
  max-width: 100%;
  max-height: 100%;
}


